import { Box, Flex, Text, VStack } from "@chakra-ui/react";
import { useGetToken } from "../hooks/useAuth";
import HeaderPage from "../components/HeaderPage";

const Perfil = () => {
  const { user } = useGetToken();

  return (
    <>
      <HeaderPage showEdit={false} />
      <Flex
        minWidth={{ md: "inherit", base: "inherit" }}
        mt="28"
        direction={{ lg: "row", base: "column" }}
        w="full"
        p={{ lg: 12, base: 4 }}
      >
        <Box flex="1" maxW={"3xl"} mx="auto">
          <VStack p="6" rounded="xl" bg="white" shadow={"lg"}>
            <Text>{user?.descripcion}</Text>
          </VStack>
        </Box>
      </Flex>
    </>
  );
};

export default Perfil;
