import { TimeIcon } from "@chakra-ui/icons";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Text,
  VStack,
  Link as ChakraLink,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Link as ReactRouterLink } from "react-router-dom";
import { useDeleteNotificationMutation } from "../store/heraApi";
import { useEffect } from "react";
import { useGetToken } from "../hooks/useAuth";

dayjs.extend(relativeTime);

const NotificationCard = ({ notification, notificationsFetcher }) => {
  const fecha = dayjs(notification?.createdAt);
  const [deleteNoty, { isLoading, isSuccess }] =
    useDeleteNotificationMutation();
  const { user } = useGetToken();

  useEffect(() => {
    if (isSuccess) notificationsFetcher(user.id).unwrap();
  }, [isSuccess, notificationsFetcher, user]);

  return (
    <Card w="full" my="4">
      <CardHeader pb="0">
        <HStack>
          <TimeIcon color="gray.400" />
          <Text fontSize={"sm"} fontWeight={100} color="gray.400">
            {fecha.fromNow()}
          </Text>
        </HStack>
      </CardHeader>

      <CardBody w="full">
        <VStack>
          <ChakraLink as={ReactRouterLink} to="/tareas" fontSize={"sm"}>
            {notification.comment}:{" "}
            <strong>{notification?.fk_activity?.name}</strong>
          </ChakraLink>
          <Button
            size="xs"
            variant={"outline"}
            colorScheme="red"
            mt="2"
            isLoading={isLoading}
            onClick={() => deleteNoty(notification.id).unwrap()}
          >
            marcar como leído
          </Button>
        </VStack>
      </CardBody>
    </Card>
  );
};

export default NotificationCard;
