import dayjs from "dayjs";
import { find } from "lodash";

function connection() {
  if (process.env.REACT_APP_BACKEND_URL) {
    return process.env.REACT_APP_BACKEND_URL;
  }
  return process.env.NODE_ENV === "production"
    ? "https://api.pinturashera.com.mx/"
    : "https://hera-backend-seven.vercel.app/";
}

export const BACKEND_SERVER = connection();

export const isJson = (json) => {
  try {
    return JSON.parse(json);
  } catch (error) {
    return false;
  }
};

export const TYPES = {
  PROCESS: "PROCESS",
  OPERATIVE: "OPERATIVE",
  PROGRAMMED: "PROGRAMMED",
};

export const TYPE_PROCESS = {
  PROCESS: "PROCESS",
  DOCUMENT: "DOCUMENT",
};

export const TYPES_SP = {
  procesos: "PROCESS",
  operativas: "OPERATIVE",
  programadas: "PROGRAMMED",
};

export const STATUS = {
  PENDIENTE: "Pendiente",
  EN_CURSO: "En curso",
  REALIZADO: "Realizado",
  CON_RETRASO: "Con retraso",
};

export const ROLES = {
  ADMIN: "admin",
  USER: "user",
  SUPERVISOR: "supervisor",
};

export const TOAST_TIME = 3000;

export const tareasPorTiempo = (overallData, type) => {
  const startOfTime = dayjs().startOf(type);
  const endOfTime = dayjs().endOf(type);
  return overallData.reduce((acc, activity) => {
    const nextDate = dayjs.utc(activity.next_date);
    if (nextDate) {
      if (nextDate.isBetween(startOfTime, endOfTime)) acc.push(activity);
    }
    return acc;
  }, []);
};

export const tareasParaHoy = (overallData) => {
  const dataFiltered = overallData.reduce((acc, activity) => {
    const nextDate = dayjs.utc(activity.next_date);
    if (nextDate) {
      const perteneceAesteDia = nextDate.isSame(dayjs(), "day");
      const esUnDiaAnterior = nextDate.isBefore(dayjs(), "day");
      if (perteneceAesteDia || esUnDiaAnterior) acc.push(activity);
    }
    return acc;
  }, []);
  return dataFiltered;
};

export function iOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

export const bodySelection = () => {
  window.document.getElementsByTagName("body")[0].style = "";
};

// export const findNextDate = (datesArray) => {
//   const today = dayjs();
//   let nextDate = null;

//   datesArray.forEach((date) => {
//     if (date.isSame(today, "day")) {
//       nextDate = date;
//     } else if (date.isAfter(today)) {
//       if (nextDate === null || date.isBefore(nextDate)) {
//         nextDate = date;
//       }
//     }
//   });

//   return nextDate;
// };

// export const getNextDate = (calendar) => {
//   const datesArray = calendar.map(({ end }) => dayjs.utc(end));
//   return findNextDate(datesArray);
// };

// export const getActivitiesFilteredFromCalendarAndChecks = (activity) => {
//   const checks = get(activity, "checks", []);
//   const calendar = get(activity, "calendar", []);
//   return calendar.filter(({ start }) => {
//     return !find(checks, ({ date_check }) => {
//       return dayjs.utc(start).isSame(dayjs.utc(date_check), "day");
//     });
//   });
// };

export const CHECK_VALIDATOR = ({ next_date, calendar, checks }, typeRol) => {
  const nextDate = dayjs.utc(next_date);
  const founded = find(calendar, ({ end }) => {
    return dayjs.utc(end).isSame(nextDate, "day");
  });
  const ifChecked = find(checks, ({ date_check, type_user_checked }) => {
    const dayToCompare = dayjs.utc(founded?.end);
    const ends = dayjs.utc(date_check);
    return (
      ends.isSame(dayToCompare, "day") &&
      type_user_checked === typeRol.toUpperCase()
    );
  });
  return ifChecked;
};

export const obtenerFechaActual = (fechaString) => {
  const fecha = new Date(fechaString);

  const anio = fecha.getFullYear();
  const mes = String(fecha.getMonth() + 1).padStart(2, "0");
  const dia = String(fecha.getDate()).padStart(2, "0");

  return `${anio}-${mes}-${dia}`;
};
