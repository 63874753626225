import Api from "./api";

export const LoginMethod = async (username, password) => {
  try {
    const api = new Api();
    const data = await api.http.post("v1/auth/login", { username, password });
    if (data?.user) {
      localStorage.setItem("session", JSON.stringify(data));
      return { error: false };
    }
    return { error: true, message: data.error };
  } catch (error) {
    return {
      error: true,
      message:
        error.message ??
        error.error ??
        "Algo malo sucedió, intenta después de unos minutos.",
    };
  }
};
