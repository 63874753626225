import { Flex, Stack, Tag, Text } from "@chakra-ui/react";
import { STATUS, TYPES } from "../constans/utils";
import TareaEntregaTable from "./TareaEntregaTable";

const TareaCard = ({
  name,
  fk_estatus: { name: statusName },
  type,
  daily,
  weekly,
  periodicity_weekly,
  monthly,
  monthly_periodicity_days,
  yearly,
  yearly_each_periodicity_months,
  next_date,
  calendar,
  checks,
}) => {
  const setStatus = (statusName) => {
    switch (statusName) {
      case STATUS.EN_CURSO:
        return "orange.500";
      case STATUS.REALIZADO:
        return "green.500";
      case STATUS.CON_RETRASO:
        return "red.500";
      default:
        return "gray.500";
    }
  };
  return (
    <Stack
      gap={4}
      w="full"
      px="1"
      py="1"
      direction={{ xl: "row", base: "column" }}
    >
      <Flex flex="1" alignItems={"center"} justifyContent={"start"}>
        <Text fontSize="sm">{name}</Text>
      </Flex>
      <Flex flex="1" alignItems={"center"} justifyContent={"end"}>
        <TareaEntregaTable
          dataDetail={{
            daily,
            weekly,
            periodicity_weekly,
            monthly,
            monthly_periodicity_days,
            yearly,
            yearly_each_periodicity_months,
            calendar,
            checks,
            next_date,
          }}
        />
      </Flex>
      <Flex flex="1" alignItems={"center"} justifyContent={"end"}>
        {type !== TYPES.OPERATIVE && (
          <Tag px="6" py="2" bgColor={setStatus(statusName)} color="white">
            {statusName}
          </Tag>
        )}
      </Flex>
    </Stack>
  );
};

export default TareaCard;
