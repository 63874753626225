import React, { useEffect, useState } from "react";
import {
  Flex,
  Button,
  Input,
  Image,
  Heading,
  useColorModeValue,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";

import { useGetToken } from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { LoginMethod } from "../constans/login";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { token } = useGetToken();
  const toast = useToast();

  useEffect(() => {
    if (token) {
      navigate("/inicio");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <Flex position="relative" bg="brand.grays2">
      <Flex
        h="100vh"
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
        mb="30px"
      >
        <Flex
          w="100%"
          h="100%"
          alignItems="center"
          justifyContent="center"
          mb="60px"
        >
          <Flex
            zIndex="2"
            direction="column"
            w="445px"
            background="transparent"
            borderRadius="15px"
            p="40px"
            mx={{ base: "100px" }}
            m={{ base: "20px", md: "auto" }}
            boxShadow={useColorModeValue(
              "0px 5px 14px rgba(0, 0, 0, 0.3)",
              "unset"
            )}
            bg="white"
          >
            <Image src="/logo.svg" w="32" mx="auto" mb="4" />
            <Heading fontWeight={500} fontSize="18" textAlign={"center"} my="8">
              Accede a tu cuenta
            </Heading>
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                if (username === "" || password === "") {
                  toast({
                    title: "Oops!",
                    description:
                      "Ingresa username y la contraseña para continuar",
                    status: "warning",
                    duration: 3000,
                    isClosable: true,
                  });
                  return;
                }
                setLoading(true);
                const objectLogin = await LoginMethod(username, password);
                if (objectLogin.error === false) {
                  setLoading(false);
                  navigate("/inicio");
                } else {
                  toast({
                    title: "Oops!",
                    description: objectLogin.message,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                  });
                  setLoading(false);
                }
              }}
            >
              <Input
                rounded="lg"
                fontSize="sm"
                ms="4px"
                type="text"
                placeholder="username"
                mb="4"
                onInput={(e) => setUsername(e.target.value)}
                required
              />
              <Input
                rounded="lg"
                fontSize="sm"
                ms="4px"
                type="password"
                placeholder="contraseña"
                mb="4"
                onInput={(e) => setPassword(e.target.value)}
                required
              />
              <Button
                bg="brand.blue1"
                color="white"
                w="100%"
                h="45"
                mt="6"
                type="submit"
                isLoading={loading}
                loadingText="Iniciando... Por favor espere..."
              >
                Iniciar sesión
              </Button>
            </form>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Login;
