import {
  Flex,
  Container,
  Heading,
  Stack,
  Text,
  Image,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";

const ErrorPage = () => {
  return (
    <Container maxW={"5xl"}>
      <Stack
        textAlign={"center"}
        align={"center"}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
      >
        <Heading
          fontWeight={600}
          fontSize={{ base: "3xl", sm: "4xl", md: "6xl" }}
          lineHeight={"110%"}
        >
          Oops!{" "}
          <Text as={"span"} color={"orange.400"}>
            Error 404
          </Text>
        </Heading>
        <Text color={"gray.500"} maxW={"3xl"}>
          Al parecer la página que buscabas no existe. Usa el siguiente link
          para redirigir a la página de inicio
        </Text>
        <Stack spacing={6} direction={"row"}>
          <ChakraLink
            as={ReactRouterLink}
            rounded={"full"}
            px={6}
            py="2"
            color="white"
            colorScheme={"orange"}
            bg={"orange.400"}
            _hover={{ bg: "orange.500" }}
            to="/inicio"
          >
            Regresar a inicio
          </ChakraLink>
        </Stack>
        <Flex w={"full"}>
          <Image
            height={{ sm: "24rem", lg: "28rem" }}
            mt={{ base: 12, sm: 16 }}
            alt="connection"
            src="https://i.ibb.co/ck1SGFJ/Group.png"
            mx="auto"
          />
        </Flex>
      </Stack>
    </Container>
  );
};

export default ErrorPage;
