import { useEffect, useState } from "react";
import {
  VStack,
  Box,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  DrawerHeader,
  useDisclosure,
  HStack,
  Button,
  useToast,
  Textarea,
  Flex,
  Image,
  Text,
  Heading,
  IconButton,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import CommentCard from "../components/CommentCard";
import {
  useLazyGetProcessQuery,
  useLazyGetCommentsQuery,
  useSetCommentMutation,
  useDeleteActivityMutation,
  useDeleteCommentMutation,
  useLazyGetAreasByUserQuery,
} from "../store/heraApi";
import Markdown from "react-markdown";
import { pdficon } from "../assets/icons/icons";
import { get } from "lodash";
import { useGetToken } from "../hooks/useAuth";
import { ROLES, TYPE_PROCESS } from "../constans/utils";
import DocumentsForm from "../components/forms/DocumentsForm";
import Api from "../constans/api";
import useProcessDataHandler from "../hooks/useProcessDataHandler";

const Documentos = () => {
  const [comment, setComment] = useState("");
  const [edit, setEdit] = useState(false);
  const [dataDetail, setDataDetail] = useState({});

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user, token } = useGetToken();
  const {
    isOpen: isDetailOpen,
    onOpen: onDetailOpen,
    onClose: onDetailClose,
  } = useDisclosure();
  const [fetcher, { data }] = useLazyGetProcessQuery();
  const [
    fetchComment,
    {
      isError: isCommentError,
      isSuccess: isCommentSuccess,
      isLoading: isCommentLoading,
    },
  ] = useSetCommentMutation();
  const [fetchComments, { data: dataComments }] = useLazyGetCommentsQuery();
  const [fetchDelete, { isSuccess, isError }] = useDeleteActivityMutation();
  const [fetchAreasByUser, { data: areasUserData }] =
    useLazyGetAreasByUserQuery();
  const [
    commentDeleterFetcher,
    { isSuccess: isCommentDeleted, isError: isCommentDeletedError },
  ] = useDeleteCommentMutation();

  const toast = useToast();
  const overallData = useProcessDataHandler(
    data,
    areasUserData,
    user,
    TYPE_PROCESS.DOCUMENT,
    false
  );

  const closeDetailHandler = () => {
    onDetailClose();
    setDataDetail({});
  };

  useEffect(() => {
    setEdit(isOpen);
    if (!isOpen) setDataDetail({});
  }, [isOpen]);

  useEffect(() => {
    if (user?.id) fetchAreasByUser(user?.id).unwrap();
  }, [fetchAreasByUser, user]);

  useEffect(() => {
    if (dataDetail?.uuid) {
      fetchComments(dataDetail?.uuid).unwrap();
    }
  }, [fetchComments, dataDetail?.uuid, isCommentSuccess, isCommentDeleted]);

  useEffect(() => {
    if (isSuccess) onDetailClose();
  }, [isSuccess, onDetailClose]);

  useEffect(() => {
    const params = new URLSearchParams({ Activities_Areas_Assigned: true });
    fetcher(params).unwrap();
  }, [fetcher, isSuccess, isCommentSuccess, isOpen]);

  useEffect(() => {
    if (isCommentError || isError || isCommentDeletedError) {
      toast({
        title: "Ooops!",
        description: "Algo malo sucedió, intenta de nuevo.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [isCommentError, isError, isCommentDeletedError, toast]);

  useEffect(() => {
    if (isCommentSuccess || isSuccess || isCommentDeleted) {
      toast({
        title: "¡Exito!",
        description: "Acción realizada con éxito.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      setComment("");
    }
  }, [isCommentSuccess, isSuccess, isCommentDeleted, toast]);

  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader fontWeight={900}>Nuevo documento</DrawerHeader>

          <DrawerBody>
            <VStack>
              <DocumentsForm
                toast={toast}
                isEditin={edit}
                dataEdit={dataDetail}
              />
              {/* <CommentCard /> */}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Drawer
        isOpen={isDetailOpen}
        placement="right"
        onClose={closeDetailHandler}
        size={dataDetail?.description ? "full" : "lg"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader
            fontSize={"2rem"}
            fontWeight={900}
            textAlign={"center"}
            textTransform={"capitalize"}
          >
            {dataDetail.name}
          </DrawerHeader>

          <DrawerBody>
            {user?.rol?.join("") !== ROLES.USER && (
              <HStack justifyContent={"center"} my="2">
                <Button
                  bg="red.400"
                  color="white"
                  size="xs"
                  onClick={() => {
                    fetchDelete(dataDetail.uuid).unwrap();
                  }}
                >
                  Eliminar documento
                </Button>
              </HStack>
            )}
            {dataDetail?.description && (
              <Box
                maxW="4xl"
                w="full"
                mx="auto"
                borderWidth="1px"
                bordercolor="gray.200"
                rounded="lg"
                mb="12"
                mt="4"
                px="8"
                py="6"
              >
                <Markdown>{dataDetail?.description}</Markdown>
              </Box>
            )}
            <Flex justifyContent={"center"}>
              {dataDetail?.uuid && (
                <Button
                  variant="link"
                  colorScheme="brand.blue.500"
                  onClick={async () => {
                    const api = new Api();
                    const blob = await api.http.getStream(
                      `v1/activity/download/files/${dataDetail?.uuid}`,
                      token
                    );
                    const urlObject = URL.createObjectURL(blob);
                    // Abrir el PDF en un nuevo tab del navegador
                    window.open(urlObject, "_blank");
                  }}
                >
                  <HStack
                    border="1px solid"
                    rounded="xl"
                    p="3"
                    borderColor="gray.200"
                  >
                    <Image src={pdficon} />
                    <Text>
                      {get(
                        dataDetail,
                        "additional.file.name",
                        dataDetail?.file_url
                      )}
                    </Text>
                  </HStack>
                </Button>
              )}
            </Flex>
            <VStack maxW="4xl" w="full" mx="auto" my="4">
              <Textarea
                value={comment}
                onInput={(e) => setComment(e.target.value)}
              />
              <Button
                variant="outline"
                color="brand.blue1"
                ml="auto"
                fontWeight={400}
                borderWidth={"1px"}
                borderColor={"brand.blue1"}
                loadingText="Enviando información"
                isLoading={isCommentLoading}
                onClick={() => {
                  if (comment) {
                    fetchComment({
                      uuid: dataDetail.uuid,
                      payload: { comment },
                    });
                  }
                }}
              >
                Comentar
              </Button>
              {(dataComments ?? []).map((comment, key) => (
                <CommentCard
                  key={`comment-${key}`}
                  {...comment}
                  user={user}
                  fetcher={commentDeleterFetcher}
                />
              ))}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <VStack mt="12" w={{ base: "full", md: "3xl" }} p="2">
        <HStack w="full" mb="8">
          <Heading py="3" fontSize="1.5rem">
            Compartidos
          </Heading>
          <Button
            variant="outline"
            color="brand.blue1"
            ml="auto"
            fontWeight={400}
            borderWidth={"1px"}
            borderColor={"brand.blue1"}
            onClick={onOpen}
          >
            Crear nota
          </Button>
        </HStack>
        {overallData.map((process, i) => (
          <HStack
            key={`item-${i}-${process.uuid}`}
            my="1"
            bg="white"
            rounded="lg"
            w="full"
            textAlign={"left"}
            py="3"
            px="4"
          >
            <Text
              cursor="pointer"
              onClick={() => {
                setDataDetail(process);
                onDetailOpen();
              }}
            >
              {process.name}
            </Text>
            {!user?.rol?.includes("user") && (
              <HStack ml="auto">
                <IconButton
                  icon={<EditIcon />}
                  onClick={() => {
                    onOpen();
                    setDataDetail(process);
                  }}
                />
              </HStack>
            )}
          </HStack>
        ))}
      </VStack>
    </>
  );
};

export default Documentos;
