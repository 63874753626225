import { configureStore } from "@reduxjs/toolkit";
import { heraApi } from "./heraApi";
import actividadesSlice from "./actividadesSlice";

const store = configureStore({
  reducer: {
    [heraApi.reducerPath]: heraApi.reducer,
    actividades: actividadesSlice,
    // filtered: filtersSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(heraApi.middleware),
});

export default store;
