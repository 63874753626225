import {
  Avatar,
  Box,
  HStack,
  IconButton,
  Text,
  VStack,
  WrapItem,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";

import bgDashboard from "../assets/bg-dashboard.png";
import { useGetToken } from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const HeaderPage = ({ showEdit = true }) => {
  const { user } = useGetToken();
  const navigate = useNavigate();

  return (
    <Box
      w="full"
      bgImg={bgDashboard}
      h="56"
      position={"relative"}
      backgroundPosition={"center"}
      backgroundRepeat={"no-repeat"}
      backgroundSize={"cover"}
    >
      <HStack
        p="6"
        rounded="xl"
        bg="white"
        shadow="xl"
        position={"absolute"}
        left="0"
        right="0"
        bottom="-12"
        w={{ base: "full", md: "lg" }}
        mx="auto"
      >
        <WrapItem>
          <Avatar
            size="lg"
            name={user?.name}
            borderColor="brand.blue1"
            borderWidth={"1px"}
            mr="2"
          />
        </WrapItem>
        <VStack alignItems={"start"} mr="auto">
          <Text fontSize="18" fontWeight={900} mb="0">
            {user?.name}
          </Text>
          <Text fontSize="xs" fontWeight={100} textTransform={"uppercase"}>
            {user?.rol?.join("")}
          </Text>
        </VStack>
        {showEdit && (
          <IconButton
            bg="white"
            rounded="full"
            borderWidth="1px"
            borderColor="brand.blue1"
            aria-label="Search database"
            icon={<EditIcon />}
            onClick={() => navigate("/perfil")}
          />
        )}
      </HStack>
    </Box>
  );
};

export default HeaderPage;
