import { useReducer, useEffect, useState, useRef } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Select as ChakraSelect,
  Button,
  Flex,
  useToast,
  Divider,
  FormHelperText,
  IconButton,
  HStack,
  Text,
  Image,
} from "@chakra-ui/react";
import { AttachmentIcon, DeleteIcon } from "@chakra-ui/icons";
import { cloneDeep, set } from "lodash";
import Select from "react-select";
import MDEditor from "@uiw/react-md-editor";

import {
  useGetUsersQuery,
  useSetFileToProcessMutation,
  useSetNewActivityMutation,
  useUpdateWorkMutation,
} from "../../store/heraApi";
import { ROLES, TOAST_TIME, TYPES } from "../../constans/utils";
import dayjs from "dayjs";
import { pdficon } from "../../assets/icons/icons";

const STATE = {
  type: "",
  name: "",
  description: "",
  daily: true,
  daily_days: "",
  weekly: false,
  periodicity_weekly: "",
  monthly: false,
  monthly_periodicity_days: "",
  monthly_each_days: "",
  yearly: false,
  yearly_each_periodicity_months: "",
  yearly_periodicity_months: "",
  yearly_periodicity_weeks: "",
  yearly_each_days: "",
  start_date: "",
  finish_date: "",
  users_assigned: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INPUT":
      const cloned = cloneDeep(state);
      set(cloned, action.key, action.value);
      return cloned;
    case "RESET":
      const clonedState = cloneDeep(STATE);
      return clonedState;
    default:
      return state;
  }
};

const DAYS_SELECT = [
  { label: "Lunes", value: "Mon" },
  { label: "Martes", value: "Tue" },
  { label: "Miércoles", value: "Wed" },
  { label: "Jueves", value: "Thu" },
  { label: "Viernes", value: "Fra" },
  { label: "Sábado", value: "Sat" },
  { label: "Domingo", value: "Sun" },
];

const arrOpts = ["Diariamente", "Semanalmente", "Mensualmente", "Anualmente"];

const ActivityForm = ({ user, dataDetail, inEditionMode }) => {
  const inputFile = useRef(null);
  const [file, setFile] = useState(null);
  const [overallUsers, setOverallUsers] = useState([]);
  const [modelObject, dispatch] = useReducer(reducer, STATE);
  const { data: dataUsers } = useGetUsersQuery("userType");
  const [setNewProyect, { data: dataNewProj, isLoading, isError, isSuccess }] =
    useSetNewActivityMutation();
  const [
    updateWork,
    {
      isLoading: isUpdateLoading,
      isError: isUpdateError,
      isSuccess: isUpdateSuccess,
    },
  ] = useUpdateWorkMutation();
  const [
    setFileFetcher,
    {
      isLoading: isLoadingFile,
      isError: isErrorFile,
      isSuccess: isSuccessFile,
      isUninitialized: isUninitializedFile,
    },
  ] = useSetFileToProcessMutation();
  const toast = useToast();

  useEffect(() => {
    if (inEditionMode && Object.keys(dataDetail).length > 0) {
      handlerReducer(dataDetail.type, "type");
      handlerReducer(dataDetail.name, "name");
      handlerReducer(dataDetail.description, "description");
      handlerReducer(dataDetail.daily, "daily");
      handlerReducer(dataDetail.daily_days, "daily_days");
      handlerReducer(dataDetail.weekly, "weekly");
      handlerReducer(dataDetail.periodicity_weekly, "periodicity_weekly");
      handlerReducer(dataDetail.monthly, "monthly");
      handlerReducer(
        dataDetail.monthly_periodicity_days,
        "monthly_periodicity_days"
      );
      handlerReducer(dataDetail.monthly_each_days, "monthly_each_days");
      handlerReducer(dataDetail.yearly, "yearly");
      handlerReducer(
        dataDetail.yearly_each_periodicity_months,
        "yearly_each_periodicity_months"
      );
      handlerReducer(
        dataDetail.yearly_periodicity_months,
        "yearly_periodicity_months"
      );
      handlerReducer(
        dataDetail.yearly_periodicity_weeks,
        "yearly_periodicity_weeks"
      );
      handlerReducer(dataDetail.yearly_each_days, "yearly_each_days");
      handlerReducer(
        dataDetail.start_date
          ? dayjs(dataDetail.start_date).format("YYYY-MM-DD")
          : "",
        "start_date"
      );
      handlerReducer(
        dataDetail.finish_date
          ? dayjs(dataDetail.finish_date).format("YYYY-MM-DD")
          : "",
        "finish_date"
      );
      handlerReducer(dataDetail.users_assigned, "users_assigned");
    }
  }, [inEditionMode, dataDetail]);

  useEffect(() => {
    if (isError || isUpdateError || isErrorFile)
      toast({
        title: "Ooops!",
        description: "Hubo un error al querer guardar, intenta de nuevo",
        status: "error",
        duration: TOAST_TIME,
        isClosable: true,
      });
  }, [isError, isUpdateError, isErrorFile, toast]);

  useEffect(() => {
    if (isSuccess || isUpdateSuccess) {
      toast({
        title: "¡Exito!",
        description: "Se guardo con éxito",
        status: "success",
        duration: TOAST_TIME,
        isClosable: true,
      });
      dispatch({ type: "RESET" });
    }
  }, [isSuccess, isUpdateSuccess, toast]);

  useEffect(() => {
    if (isSuccessFile) {
      toast({
        title: "¡Exito!",
        description: "Se añadió el archivo al proceso con éxito",
        status: "success",
        duration: TOAST_TIME,
        isClosable: true,
      });
      setFile(null);
    }
  }, [isSuccessFile, toast]);

  useEffect(() => {
    if (
      isSuccess &&
      isUninitializedFile &&
      dataNewProj &&
      file &&
      dataNewProj.type === TYPES.PROGRAMMED
    ) {
      setFileFetcher({ uuid: dataNewProj.uuid, file }).unwrap();
    }
  }, [isSuccess, isUninitializedFile, dataNewProj, file, setFileFetcher]);

  useEffect(() => {
    if (dataUsers && Array.isArray(dataUsers)) {
      setOverallUsers(
        dataUsers.map(({ id, name, username }) => {
          return { label: `${name} - ${username}`, value: id };
        })
      );
    }
  }, [dataUsers]);

  const handlerReducer = (value, key) => {
    dispatch({ type: "INPUT", value, key });
  };

  const showTareaHandler = (value, key) => {
    arrOpts.forEach((val) =>
      dispatch({ type: "INPUT", value: false, key: optsFilter(val) })
    );
    const objStateKey = optsFilter(key);
    dispatch({ type: "INPUT", value, key: objStateKey });
  };

  const optsFilter = (value) => {
    switch (value) {
      case "Diariamente":
        return "daily";
      case "Semanalmente":
        return "weekly";
      case "Mensualmente":
        return "monthly";
      case "Anualmente":
        return "yearly";
      default:
        return value;
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        // if (
        //   modelObject.users_assigned.length === 0 &&
        //   user?.rol?.join("") !== ROLES.USER
        // ) {
        //   toast({
        //     title: "Ooops!",
        //     description:
        //       "Antes de enviar la información llena todos los campos requeridos.",
        //     status: "warning",
        //     duration: TOAST_TIME,
        //     isClosable: true,
        //   });
        //   return;
        // }
        if (inEditionMode && dataDetail.uuid) {
          return updateWork({ payload: modelObject, uuid: dataDetail.uuid });
        }
        setNewProyect(modelObject).unwrap();
      }}
    >
      <FormControl mt="4" mb="2" isRequired>
        <FormLabel>Tipo</FormLabel>
        <ChakraSelect
          placeholder="Seleccione el tipo"
          onChange={(e) => handlerReducer(e.target.value, "type")}
          value={modelObject.type}
        >
          <option value={TYPES.OPERATIVE}>Operativa</option>
          <option value={TYPES.PROGRAMMED}>Programada</option>
        </ChakraSelect>
      </FormControl>
      <FormControl mt="4" mb="2" isRequired>
        <FormLabel>Nombre</FormLabel>
        <Input
          type="text"
          value={modelObject.name}
          onInput={(e) => handlerReducer(e.target.value, "name")}
        />
      </FormControl>
      <FormControl mt="4" mb="2">
        <FormLabel>Descripción</FormLabel>
        <MDEditor
          value={modelObject.description}
          onChange={(value) => handlerReducer(value, "description")}
          style={{ width: "100%" }}
        />
      </FormControl>
      {modelObject.type !== "" && (
        <FormControl mt="4" mb="2" isRequired>
          <FormLabel>Mostrar tarea: </FormLabel>
          <ChakraSelect
            placeholder="Seleccione..."
            onChange={(e) => showTareaHandler(true, e.target.value)}
          >
            {modelObject.type === TYPES.PROGRAMMED
              ? arrOpts.slice(1).map((opt) => (
                  <option key={`opt-${opt}`} value={opt}>
                    {opt}
                  </option>
                ))
              : arrOpts.map((opt) => (
                  <option key={`opt-${opt}`} value={opt}>
                    {opt}
                  </option>
                ))}
          </ChakraSelect>
        </FormControl>
      )}
      {modelObject.weekly === true && (
        <FormControl mt="4" mb="2" isRequired>
          <FormLabel>
            Seleccione los días de la semana que se mostrará
          </FormLabel>
          <Select
            isMulti
            options={DAYS_SELECT}
            value={modelObject.periodicity_weekly}
            onChange={(value) => handlerReducer(value, "periodicity_weekly")}
          />
        </FormControl>
      )}
      {modelObject.monthly === true && (
        <FormControl mt="4" mb="2" isRequired>
          <FormLabel>Seleccione los días del mes a mostrar</FormLabel>
          <Select
            isMulti
            options={Array.from(Array(31).keys()).map((num) => {
              return {
                label: num + 1,
                value: num + 1,
              };
            })}
            value={modelObject.monthly_periodicity_days}
            onChange={(value) =>
              handlerReducer(value, "monthly_periodicity_days")
            }
          />
          <FormHelperText fontSize="xs">
            Para los meses que no tengas 31 días se considerará solo los últimos
            de estos.
          </FormHelperText>
        </FormControl>
      )}
      {modelObject.yearly === true && (
        <FormControl mt="4" mb="2" isRequired>
          <FormLabel>Seleccione la periodicidad por cada mes</FormLabel>
          <ChakraSelect
            placeholder="seleccione..."
            value={modelObject.yearly_each_periodicity_months}
            onChange={(e) =>
              handlerReducer(
                Number(e.target.value),
                "yearly_each_periodicity_months"
              )
            }
          >
            {Array.from(Array(12).keys()).map((num) => (
              <option key={`opt-${num}-yearly`} value={num + 1}>
                {num + 1}
              </option>
            ))}
          </ChakraSelect>
          <FormHelperText fontSize="xs">
            La tarea se mostrará cada:{" "}
            {modelObject.yearly_each_periodicity_months} mes(es)
          </FormHelperText>
        </FormControl>
      )}
      <Divider my="4" />
      {user?.rol?.join("") !== ROLES.USER && (
        <FormControl mt="4" mb="2">
          <FormLabel>Asignar a:</FormLabel>
          <Select
            isMulti
            options={overallUsers}
            value={modelObject.users_assigned}
            onChange={(value) => handlerReducer(value, "users_assigned")}
          />
        </FormControl>
      )}
      {modelObject.type !== "" && (
        <Flex direction={{ md: "row", base: "column" }}>
          <FormControl mt="4" mb="2" mr={{ md: "2", base: 0 }} isRequired>
            <FormLabel>Fecha de INICIO</FormLabel>
            <Input
              type="date"
              value={modelObject.start_date}
              min={new Date().toISOString().split("T")[0]}
              onInput={(e) => handlerReducer(e.target.value, "start_date")}
            />
          </FormControl>
          <FormControl mt="4" mb="2" ml={{ md: "2", base: 0 }}>
            <FormLabel>Fecha de FINALIZACIÓN</FormLabel>
            <Input
              type="date"
              value={modelObject.finish_date}
              min={modelObject.start_date}
              onInput={(e) => handlerReducer(e.target.value, "finish_date")}
            />
          </FormControl>
        </Flex>
      )}

      {modelObject.type === TYPES.PROGRAMMED && modelObject.type !== "" && (
        <>
          <FormControl>
            <FormLabel>
              <Input
                name="file"
                hidden
                type="file"
                accept=".pdf"
                ref={(e) => {
                  inputFile.current = e;
                }}
                onChange={(e) => setFile(e.target.files[0])}
              />
            </FormLabel>
            <Button
              type="button"
              leftIcon={<AttachmentIcon />}
              color="brand.blue1"
              variant="transparent"
              isLoading={isLoadingFile}
              onClick={() => inputFile?.current?.click()}
            >
              Adjuntar archivo
            </Button>
          </FormControl>
          <Flex>
            {file && (
              <HStack
                border="1px solid"
                rounded="xl"
                p="3"
                borderColor="gray.200"
              >
                <IconButton
                  bg="none"
                  color="red.600"
                  icon={<DeleteIcon />}
                  onClick={() => setFile(null)}
                />
                <Image src={pdficon} />
                <Text>{file?.name}</Text>
              </HStack>
            )}
          </Flex>
        </>
      )}

      <Button
        type="submit"
        isLoading={isLoading || isUpdateLoading || isLoadingFile}
        loadingText="Enviando información"
        mt="6"
        bg="brand.blue1"
        color="white"
      >
        Enviar información
      </Button>
    </form>
  );
};

export default ActivityForm;
