import {
  Card,
  CardBody,
  Text,
  CardHeader,
  HStack,
  WrapItem,
  Avatar,
  Image,
  Button,
  CardFooter,
} from "@chakra-ui/react";
import { TimeIcon, DeleteIcon } from "@chakra-ui/icons";
import { like } from "../assets/icons/icons";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useNewLikeMutation } from "../store/heraApi";

dayjs.extend(relativeTime);

const CommentCard = ({
  id,
  activity_uuid,
  comment,
  createdAt,
  fk_user,
  user,
  fetcher,
  Activities_Comments_Likes,
}) => {
  const commentFromUser = user.id === fk_user.id;
  const fecha = dayjs(createdAt);

  const [fetchLike, { data }] = useNewLikeMutation();

  const likes =
    Activities_Comments_Likes?.reduce((acc, { like }) => {
      return acc + (like ?? 0);
    }, 0) ?? 0;

  return (
    <Card w="full">
      <CardHeader>
        <HStack w="full">
          <HStack mr="auto">
            <WrapItem>
              <Avatar
                size="sm"
                name={fk_user.name}
                borderColor="brand.blue1"
                borderWidth={"1px"}
              />
            </WrapItem>
            <Text>{fk_user.name}</Text>
          </HStack>
          <HStack ml="auto">
            <HStack>
              <TimeIcon color="gray.400" />
              <Text fontSize={"sm"} fontWeight={100} color="gray.400">
                {fecha.fromNow()}
              </Text>
            </HStack>

            {/* <Button colorScheme="transparent">
              <Image color="gray.400" src={submenu} />
            </Button> */}
          </HStack>
        </HStack>
      </CardHeader>
      <CardBody>
        <Text>{comment}</Text>
      </CardBody>
      <CardFooter
        justify="space-between"
        flexWrap="wrap"
        borderTopWidth={"1px"}
        p="0"
      >
        <Button
          leftIcon={<Image src={like} />}
          flex="1"
          variant="ghost"
          borderRightWidth={"1px"}
          fontWeight={400}
          onClick={() => fetchLike(id).unwrap()}
        >
          <HStack>
            <Text>Like</Text>
            {data?.like && <Text>{data?.like}</Text>}
            {likes > 0 && <Text> - {likes}</Text>}
          </HStack>
        </Button>
        {commentFromUser && (
          <Button
            leftIcon={<DeleteIcon />}
            flex="1"
            variant="ghost"
            fontWeight={400}
            onClick={() => {
              fetcher({ uuid: activity_uuid, id }).unwrap();
            }}
          >
            Eliminar
          </Button>
        )}
      </CardFooter>
    </Card>
  );
};

export default CommentCard;
