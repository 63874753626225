import { useReducer, useEffect, useState, useRef } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Divider,
  Flex,
  Image,
  HStack,
  Text,
  IconButton,
  Alert,
  AlertDescription,
  AlertIcon,
  VStack,
} from "@chakra-ui/react";
import { cloneDeep, get, set } from "lodash";
import Select from "react-select";
import MDEditor from "@uiw/react-md-editor";

import {
  useSetFileToProcessMutation,
  useGetUsersQuery,
  useSetNewActivityMutation,
  useLazyGetAreasQuery,
  useUpdateActivityMutation,
} from "../../store/heraApi";
import { TOAST_TIME, TYPES, TYPE_PROCESS } from "../../constans/utils";
import { AttachmentIcon, DeleteIcon } from "@chakra-ui/icons";
import { pdficon } from "../../assets/icons/icons";
import { useGetToken } from "../../hooks/useAuth";
import Api from "../../constans/api";

const STATE = {
  type: TYPES.PROCESS,
  name: "",
  description: "",
  subtitle: "",
  start_date: "",
  finish_date: "",
  users_assigned: [],
  areas_assigned: [],
  type_process: TYPE_PROCESS.PROCESS,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INPUT":
      const cloned = cloneDeep(state);
      set(cloned, action.key, action.value);
      return cloned;
    case "RESET":
      const clonedState = cloneDeep(STATE);
      return clonedState;
    default:
      return state;
  }
};

const ProcessForm = ({ toast, isEditin, dataEdit }) => {
  const [file, setFile] = useState(null);
  const inputFile = useRef(null);
  const [overallUsers, setOverallUsers] = useState([]);
  const [overallAreas, setOverallAreas] = useState([]);
  const [setNewProyect, { data, isLoading, isError, isSuccess }] =
    useSetNewActivityMutation();
  const [
    updateProject,
    {
      isLoading: isUpdateLoading,
      isError: isUpdateError,
      isSuccess: isUpdateSuccess,
    },
  ] = useUpdateActivityMutation();
  const [
    setFileFetcher,
    {
      isLoading: isLoadingFile,
      isError: isErrorFile,
      isSuccess: isSuccessFile,
    },
  ] = useSetFileToProcessMutation();
  const { token } = useGetToken();

  const [modelObject, dispatch] = useReducer(reducer, STATE);
  const { data: dataUsers } = useGetUsersQuery("userType");
  const [areasFetcher, { data: dataAreas }] = useLazyGetAreasQuery();

  useEffect(() => {
    if (isErrorFile)
      toast({
        title: "Ooops!",
        description:
          "El archivo no se guardó correctamente. Tendras que dar de alta la tarea de nuevo.",
        status: "error",
        duration: TOAST_TIME,
        isClosable: true,
      });
    setFile(null);
  }, [isErrorFile, toast]);

  useEffect(() => {
    if (isError || isUpdateError)
      toast({
        title: "Ooops!",
        description: "Hubo un error al querer guardar, intenta de nuevo",
        status: "error",
        duration: TOAST_TIME,
        isClosable: true,
      });
  }, [isError, isUpdateError, toast]);

  useEffect(() => {
    if (isSuccess || isUpdateSuccess) {
      toast({
        title: "¡Exito!",
        description: "Se guardó con éxito",
        status: "success",
        duration: TOAST_TIME,
        isClosable: true,
      });
      // dispatch({ type: "RESET" });
    }
  }, [isSuccess, isUpdateSuccess, toast]);

  useEffect(() => {
    if (isSuccessFile) {
      toast({
        title: "¡Exito!",
        description: "Se añadió el archivo al proceso con éxito",
        status: "success",
        duration: TOAST_TIME,
        isClosable: true,
      });
      // dispatch({ type: "RESET" });
      setFile(null);
    }
  }, [isSuccessFile, toast]);

  useEffect(() => {
    areasFetcher().unwrap();
  }, [areasFetcher]);

  useEffect(() => {
    if (isEditin) {
      handlerReducer(dataEdit.name, "name");
      handlerReducer(dataEdit.description, "description");
      handlerReducer(dataEdit?.additional?.subtitle, "subtitle");

      handlerReducer(dataEdit.users_assigned, "users_assigned");
      if (Array.isArray(dataEdit.Activities_Areas_Assigned)) {
        const areas = dataEdit.Activities_Areas_Assigned.map(({ fk_areas }) => {
          return {
            label: fk_areas.name,
            value: fk_areas.id,
          };
        });
        handlerReducer(areas, "areas_assigned");
      }
    }
  }, [dataEdit, isEditin]);

  useEffect(() => {
    if (isSuccess && data && data?.uuid && file) {
      setFileFetcher({ uuid: data.uuid, file }).unwrap();
    }
  }, [isSuccess, data, setFileFetcher, file]);

  useEffect(() => {
    if (dataUsers && Array.isArray(dataUsers)) {
      setOverallUsers(
        dataUsers.map(({ id, name, username }) => {
          return { label: `${name} - ${username}`, value: id };
        })
      );
    }
  }, [dataUsers]);

  useEffect(() => {
    if (dataAreas && Array.isArray(dataAreas)) {
      setOverallAreas(
        dataAreas.map(({ id, name }) => {
          return { label: `${name}`, value: id };
        })
      );
    }
  }, [dataAreas]);

  const handlerReducer = (value, key) => {
    dispatch({ type: "INPUT", value, key });
  };

  return (
    <form
      style={{ width: "100%" }}
      onSubmit={async (e) => {
        e.preventDefault();
        if (isEditin && dataEdit.uuid) {
          return updateProject({ payload: modelObject, uuid: dataEdit.uuid });
        }
        if (modelObject.description === "" && !file) {
          return toast({
            title: "Ooops!",
            description:
              "Es necesario escribir la descripción o en su defecto adjuntar un archivo PDF",
            status: "error",
            duration: TOAST_TIME,
            isClosable: true,
          });
        }
        setNewProyect(modelObject)?.unwrap();
      }}
    >
      <FormControl mt="4" mb="2" isRequired>
        <FormLabel>Título</FormLabel>
        <Input
          type="text"
          value={modelObject.name}
          onInput={(e) => handlerReducer(e.target.value, "name")}
        />
      </FormControl>
      <FormControl mt="4" mb="2">
        <FormLabel>Subtitulo</FormLabel>
        <Input
          type="text"
          value={modelObject.subtitle}
          onInput={(e) => handlerReducer(e.target.value, "subtitle")}
        />
      </FormControl>
      <FormControl mt="4" mb="2">
        <FormLabel>Descripción</FormLabel>
        <MDEditor
          value={modelObject.description}
          onChange={(value) => handlerReducer(value, "description")}
          style={{ width: "100%" }}
        />
      </FormControl>
      <Divider my="4" />

      <FormControl mt="4" mb="2">
        <FormLabel>Asignar a las siguientes áreas:</FormLabel>
        <Select
          isMulti
          options={overallAreas}
          value={modelObject.areas_assigned}
          onChange={(value) => handlerReducer(value, "areas_assigned")}
        />
      </FormControl>
      <FormControl mt="4" mb="2">
        <FormLabel>Asignar a:</FormLabel>
        <Select
          isMulti
          options={overallUsers}
          value={modelObject.users_assigned}
          onChange={(value) => handlerReducer(value, "users_assigned")}
        />
      </FormControl>

      {modelObject.users_assigned && modelObject.users_assigned.length > 0 && (
        <Alert status="info">
          <AlertIcon />
          <AlertDescription>
            Recuerda que al asignar a una persona esta será la{" "}
            <strong>única</strong> en ver el proceso
          </AlertDescription>
        </Alert>
      )}

      <FormControl>
        <FormLabel>
          <Input
            name="file"
            hidden
            type="file"
            accept=".pdf"
            ref={(e) => {
              inputFile.current = e;
            }}
            onChange={(e) => setFile(e.target.files[0])}
          />
        </FormLabel>
        <Button
          type="button"
          leftIcon={<AttachmentIcon />}
          color="brand.blue1"
          variant="transparent"
          onClick={() => inputFile?.current?.click()}
        >
          Adjuntar archivo
        </Button>
      </FormControl>
      <Flex>
        {file && (
          <HStack border="1px solid" rounded="xl" p="3" borderColor="gray.200">
            <IconButton
              bg="none"
              color="red.600"
              icon={<DeleteIcon />}
              onClick={() => setFile(null)}
            />
            <Image src={pdficon} />
            <Text>{file?.name}</Text>
          </HStack>
        )}
      </Flex>
      {isEditin && dataEdit?.uuid && (
        <VStack>
          <Text>Documento actual: </Text>
          <Button
            variant="link"
            colorScheme="brand.blue.500"
            onClick={async () => {
              const api = new Api();
              const blob = await api.http.getStream(
                `v1/activity/download/files/${dataEdit?.uuid}`,
                token
              );
              const urlObject = URL.createObjectURL(blob);
              // Abrir el PDF en un nuevo tab del navegador
              window.open(urlObject, "_blank");
            }}
          >
            <HStack
              border="1px solid"
              rounded="xl"
              p="3"
              borderColor="gray.200"
            >
              <Image src={pdficon} />
              <Text>
                {get(dataEdit, "additional.file.name", dataEdit?.file_url)}
              </Text>
            </HStack>
          </Button>
        </VStack>
      )}
      <Button
        type="submit"
        isLoading={isLoading || isLoadingFile || isUpdateLoading}
        loadingText="Enviando información"
        mt="6"
        bg="brand.blue1"
        color="white"
      >
        Enviar información
      </Button>
    </form>
  );
};

export default ProcessForm;
