import { useEffect, useRef, useState } from "react";
import {
  Box,
  HStack,
  IconButton,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  Button,
  useDisclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useToast,
  Spinner,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";

import { copy, edit, submenu } from "../assets/icons/icons";
import { useDeleteAreaMutation, useLazyGetAreasQuery } from "../store/heraApi";
import AreaForm from "../components/forms/AreaForm";

const TareaTableRow = ({
  name,
  description,
  id,
  setUserSelected,
  deleteAction,
}) => {
  const colorScheme = "brand.blue2";

  return (
    <Tr bg="white">
      <Td
        borderColor={colorScheme}
        borderTopWidth={"1px"}
        borderBottomWidth={"1px"}
        borderLeftWidth={"1px"}
        borderBottomLeftRadius={"10px"}
        borderTopLeftRadius={"10px"}
      >
        {name}
      </Td>
      <Td
        borderColor={colorScheme}
        borderTopWidth={"1px"}
        borderBottomWidth={"1px"}
      >
        <Text fontSize="sm">{description}</Text>
      </Td>
      <Td
        textAlign={"center"}
        borderColor={colorScheme}
        borderTopWidth={"1px"}
        borderBottomWidth={"1px"}
        borderRightWidth={"1px"}
        borderBottomRightRadius={"10px"}
        borderTopRightRadius={"10px"}
      >
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<Image src={submenu} />}
            variant="none"
          />
          <MenuList>
            <MenuItem
              onClick={() =>
                setUserSelected({
                  name,
                  id,
                })
              }
              icon={<Image src={edit} />}
            >
              Editar
            </MenuItem>
            <MenuItem
              onClick={() =>
                deleteAction({
                  name,
                  id,
                })
              }
              icon={<Image src={copy} />}
            >
              Borrar
            </MenuItem>
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
};

const Areas = () => {
  const [userSelected, setUserSelected] = useState({});
  const [isDeleting, setIsDeleting] = useState(false);
  const cancelRef = useRef();

  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const {
    isOpen: isAlertOpen,
    onOpen: onOpenAlert,
    onClose: onCloseAlert,
  } = useDisclosure();
  const [refetch, { data, isLoading, isError }] = useLazyGetAreasQuery();
  const [fetchDelete, { isSuccess, isError: isDeletingUserError }] =
    useDeleteAreaMutation();
  const toast = useToast();

  useEffect(() => {
    if (userSelected?.id && !isOpen && !isDeleting) {
      onToggle();
    }
  }, [userSelected?.id, onToggle, isOpen, isDeleting]);

  useEffect(() => {
    if (isDeleting && userSelected?.id) onOpenAlert();
  }, [isDeleting, userSelected?.id, onOpenAlert]);

  useEffect(() => {
    if (isError || isDeletingUserError)
      toast({
        title: "Ooops!",
        description: "Ocurrió un error, intenta de nuevo.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
  }, [isError, isDeletingUserError, toast]);

  useEffect(() => {
    if (isSuccess)
      toast({
        title: "¡Éxito!",
        description: "Acción realizado con éxito",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
  }, [isSuccess, toast]);

  useEffect(() => {
    refetch().unwrap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, isAlertOpen]);

  const onCloseModal = () => {
    setUserSelected({});
    onClose();
  };

  const onCloseAlertHandler = () => {
    setUserSelected({});
    onCloseAlert();
    setIsDeleting(false);
  };

  const deleteHandler = () => {
    fetchDelete(userSelected.id).unwrap();
    onCloseAlertHandler();
  };

  const deleteAction = (userObject) => {
    setIsDeleting(true);
    setUserSelected(userObject);
    onClose();
  };

  return (
    <>
      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlertHandler}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Eliminar usuario
            </AlertDialogHeader>

            <AlertDialogBody>
              ¿Estas segur@? una vez eliminado no se puede regresar o deshacer
              de eliminarlo.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseAlertHandler}>
                Cancelar
              </Button>
              <Button colorScheme="red" onClick={deleteHandler} ml={3}>
                Eliminar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Modal
        isCentered
        onClose={onCloseModal}
        isOpen={isOpen}
        motionPreset="slideInBottom"
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Datos del área</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {" "}
            <AreaForm userSelected={userSelected} />{" "}
          </ModalBody>
        </ModalContent>
      </Modal>
      {isLoading ? (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      ) : (
        <Box
          minWidth={{ lg: "max-content", md: "inherit", base: "inherit" }}
          direction={{ lg: "row", base: "column" }}
          w="full"
          p={{ lg: 8, base: 4 }}
        >
          <VStack p="4" rounded="xl" bg="none">
            <HStack w="full" mb="6">
              <Button
                variant="outline"
                colorScheme="brand.blue1"
                ml="auto"
                fontWeight={400}
                onClick={onOpen}
              >
                Nueva área
              </Button>
            </HStack>

            <Table
              style={{
                borderCollapse: "separate",
                borderSpacing: "0 1em",
              }}
            >
              <Thead>
                <Tr>
                  <Th color="brand.blue1">Nombre</Th>
                  <Th color="brand.blue1">Descripción</Th>
                  <Th color="brand.blue1"></Th>
                </Tr>
              </Thead>
              <Tbody>
                {(data ?? []).map((userData, i) => (
                  <TareaTableRow
                    key={`tr_${i}`}
                    {...userData}
                    setUserSelected={setUserSelected}
                    deleteAction={deleteAction}
                  />
                ))}
              </Tbody>
            </Table>
          </VStack>
        </Box>
      )}
    </>
  );
};

export default Areas;
