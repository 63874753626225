import { useEffect, useRef, useState } from "react";
import {
  Box,
  IconButton,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  Button,
  useDisclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useToast,
  Spinner,
  Tooltip,
  Flex,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  TableContainer,
  Input,
  FormLabel,
  FormControl,
} from "@chakra-ui/react";

import { copy, edit, submenu } from "../assets/icons/icons";
import {
  useDeleteUserMutation,
  useLazyGetUsersQuery,
  useSetXlsxUsersMutation,
  useUpdatePasswordMutation,
} from "../store/heraApi";
import UserForm from "../components/forms/UserForm";
import { TOAST_TIME } from "../constans/utils";

const TareaTableRow = ({
  username,
  name,
  descripcion,
  rol,
  id,
  telefono,
  Usuario_Areas,
  setUserSelected,
  deleteAction,
}) => {
  const colorScheme = "brand.blue2";
  const [pass, setPass] = useState("");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [updateUser, { isLoading, isError, isSuccess }] =
    useUpdatePasswordMutation();
  const toast = useToast();

  useEffect(() => {
    if (isError)
      toast({
        title: "Ooops!",
        description: "Ocurrió un error, intenta de nuevo.",
        status: "error",
        duration: TOAST_TIME,
        isClosable: true,
      });
    setPass("");
  }, [isError, toast]);

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: "¡Éxito!",
        description: "Acción realizado con éxito",
        status: "success",
        duration: TOAST_TIME,
        isClosable: true,
      });
    }
    setPass("");
  }, [isSuccess, toast]);

  return (
    <>
      <AlertDialog isOpen={isOpen} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Nueva contraseña
            </AlertDialogHeader>

            <AlertDialogBody>
              <FormControl>
                <Input
                  type="password"
                  value={pass}
                  placeholder="Nueva constraseña"
                  onInput={(e) => setPass(e.target.value)}
                />
              </FormControl>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={onClose}>Cancelar</Button>
              <Button
                colorScheme="blue"
                isLoading={isLoading}
                onClick={() => {
                  if (pass) {
                    updateUser({ id, payload: { password: pass } }).unwrap();
                  }
                }}
                ml={3}
              >
                Enviar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Tr bg="white">
        <Td
          borderColor={colorScheme}
          borderTopWidth={"1px"}
          borderBottomWidth={"1px"}
          borderLeftWidth={"1px"}
          borderBottomLeftRadius={"10px"}
          borderTopLeftRadius={"10px"}
        >
          <Tooltip fontSize="sm" label={name} aria-label="A tooltip">
            {username}
          </Tooltip>
        </Td>
        <Td
          borderColor={colorScheme}
          borderTopWidth={"1px"}
          borderBottomWidth={"1px"}
        >
          {Array.isArray(rol) && (
            <Text fontSize="sm" textTransform={"uppercase"}>
              {rol.join("")}
            </Text>
          )}
        </Td>
        <Td
          borderColor={colorScheme}
          borderTopWidth={"1px"}
          borderBottomWidth={"1px"}
        >
          <Text
            fontSize="sm"
            overflow={"hidden"}
            maxW="64"
            textOverflow={"ellipsis"}
          >
            {descripcion}
          </Text>
        </Td>
        <Td
          textAlign={"center"}
          borderColor={colorScheme}
          borderTopWidth={"1px"}
          borderBottomWidth={"1px"}
          borderRightWidth={"1px"}
          borderBottomRightRadius={"10px"}
          borderTopRightRadius={"10px"}
        >
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<Image src={submenu} />}
              variant="none"
            />
            <MenuList>
              <MenuItem
                onClick={() =>
                  setUserSelected({
                    username,
                    name,
                    descripcion,
                    rol,
                    id,
                    telefono,
                    Usuario_Areas,
                  })
                }
                icon={<Image src={edit} />}
              >
                Editar
              </MenuItem>
              <MenuItem
                onClick={() =>
                  deleteAction({
                    username,
                    name,
                    descripcion,
                    rol,
                    id,
                    telefono,
                    Usuario_Areas,
                  })
                }
                icon={<Image src={copy} />}
              >
                Borrar
              </MenuItem>
              <MenuItem onClick={onOpen} icon={<Image src={edit} />}>
                Modificar contraseña
              </MenuItem>
            </MenuList>
          </Menu>
        </Td>
      </Tr>
    </>
  );
};

const Usuarios = () => {
  const inputFile = useRef(null);
  const [file, setFile] = useState(null);
  const [userSelected, setUserSelected] = useState({});
  const [isDeleting, setIsDeleting] = useState(false);
  const cancelRef = useRef();

  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const {
    isOpen: isAlertOpen,
    onOpen: onOpenAlert,
    onClose: onCloseAlert,
  } = useDisclosure();
  const [
    xlsxFetcher,
    { isSuccess: fileSuccess, isError: fileError, isLoading: fileLoading },
  ] = useSetXlsxUsersMutation();
  const [refetch, { data, isLoading, isError }] = useLazyGetUsersQuery();
  const [fetchDelete, { isSuccess, isError: isDeletingUserError }] =
    useDeleteUserMutation();
  const toast = useToast();

  useEffect(() => {
    if (userSelected?.id && !isOpen && !isDeleting) {
      onToggle();
    }
  }, [userSelected?.id, onToggle, isOpen, isDeleting]);

  useEffect(() => {
    if (isDeleting && userSelected?.id) onOpenAlert();
  }, [isDeleting, userSelected?.id, onOpenAlert]);

  useEffect(() => {
    if (isError || isDeletingUserError || fileError)
      toast({
        title: "Ooops!",
        description: "Ocurrió un error, intenta de nuevo.",
        status: "error",
        duration: TOAST_TIME,
        isClosable: true,
      });
  }, [isError, isDeletingUserError, fileError, toast]);

  useEffect(() => {
    if (isSuccess || fileSuccess) {
      toast({
        title: "¡Éxito!",
        description: "Acción realizado con éxito",
        status: "success",
        duration: TOAST_TIME,
        isClosable: true,
      });
      setFile(null);
    }
  }, [isSuccess, fileSuccess, toast]);

  useEffect(() => {
    refetch().unwrap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, isAlertOpen, fileSuccess]);

  const onCloseModal = () => {
    setUserSelected({});
    onClose();
  };

  const onCloseAlertHandler = () => {
    setUserSelected({});
    onCloseAlert();
    setIsDeleting(false);
  };

  const deleteHandler = () => {
    fetchDelete(userSelected.id).unwrap();
    onCloseAlertHandler();
  };

  const deleteAction = (userObject) => {
    setIsDeleting(true);
    setUserSelected(userObject);
    onClose();
  };

  return (
    <>
      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlertHandler}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Eliminar usuario
            </AlertDialogHeader>

            <AlertDialogBody>
              ¿Estas segur@? una vez eliminado no se puede regresar o deshacer
              de eliminarlo.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseAlertHandler}>
                Cancelar
              </Button>
              <Button colorScheme="red" onClick={deleteHandler} ml={3}>
                Eliminar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Modal
        isCentered
        onClose={onCloseModal}
        isOpen={isOpen}
        motionPreset="slideInBottom"
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Datos del usuario</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {" "}
            <UserForm userSelected={userSelected} />{" "}
          </ModalBody>
        </ModalContent>
      </Modal>
      {isLoading ? (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      ) : (
        <Box
          minWidth={{ lg: "max-content", md: "inherit", base: "inherit" }}
          direction={{ lg: "row", base: "column" }}
          w="full"
          p={{ lg: 8, base: 4 }}
        >
          <VStack p="4" rounded="xl" bg="none">
            <Flex w="full" mb="6" direction={{ base: "column", md: "row" }}>
              <FormControl>
                <FormLabel>
                  <Input
                    name="file"
                    hidden
                    type="file"
                    accept=".xlsx"
                    ref={(e) => {
                      inputFile.current = e;
                    }}
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </FormLabel>
                <Button
                  type="button"
                  color="brand.blue1"
                  variant="transparent"
                  onClick={() => inputFile?.current?.click()}
                  isLoading={fileLoading}
                  loadingText="Enviando archivo..."
                >
                  {file?.name || "Enviar usuarios por archivo xlsx"}
                </Button>
                {file?.name && (
                  <Button
                    type="button"
                    onClick={() => {
                      xlsxFetcher({ file }).unwrap();
                    }}
                  >
                    Enviar
                  </Button>
                )}
              </FormControl>
              <Button
                variant="outline"
                colorScheme="brand.blue1"
                fontWeight={400}
                onClick={onOpen}
              >
                Nuevo usuario
              </Button>
            </Flex>

            <TableContainer w="full">
              <Table
                style={{
                  borderCollapse: "separate",
                  borderSpacing: "0 1em",
                }}
              >
                <Thead>
                  <Tr>
                    <Th color="brand.blue1">Usuario</Th>
                    <Th color="brand.blue1">Puesto</Th>
                    <Th color="brand.blue1">Descripción del puesto</Th>
                    <Th color="brand.blue1"></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {(data ?? []).map((userData, i) => (
                    <TareaTableRow
                      key={`tr_${i}`}
                      {...userData}
                      setUserSelected={setUserSelected}
                      deleteAction={deleteAction}
                    />
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </VStack>
        </Box>
      )}
    </>
  );
};

export default Usuarios;
