import { createListenerMiddleware, createSlice } from "@reduxjs/toolkit";

const initialState = {
  nombre: "",
  medidas_espacios: [],
  plano: null,
};

export const actividadesSlice = createSlice({
  name: "actividades",
  initialState,
  reducers: {
    addDataToModel: (state, action) => {
      state[action.payload.key] = action.payload.data;
    },
  },
});

export const getActividadModel = (state) => state.actividades;

export const processListenerMiddleware = createListenerMiddleware();

export const {
  addDataToModel,
  setOverallModel,
  setDataSpaceToModel,
  addNewDataSpacetoModel,
  deleteDataSpacetoModel,
} = actividadesSlice.actions;

export default actividadesSlice.reducer;
