import { useEffect, useMemo } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { InfoIcon } from "@chakra-ui/icons";
import {
  VStack,
  Text,
  HStack,
  Box,
  useDisclosure,
  PopoverTrigger,
  Popover,
  IconButton,
  PopoverContent,
  FocusLock,
  PopoverArrow,
  PopoverCloseButton,
} from "@chakra-ui/react";

dayjs.extend(utc);

const PopCalendarDetail = ({ dataDetail }) => {
  if (dataDetail?.daily) {
    return (
      <VStack mb="4">
        <Text fontSize="sm">Se repite todos los días</Text>
      </VStack>
    );
  }

  if (dataDetail?.weekly) {
    return (
      <VStack mb="4">
        <Text fontSize="sm">Todas las semanas, los días: </Text>
        <HStack>
          {dataDetail?.periodicity_weekly.map(({ label }, i) => (
            <Box
              key={`weekly-${i}`}
              py="1"
              px="2"
              rounded="xl"
              border="1px"
              borderColor={"gray.300"}
              fontSize="xs"
            >
              {label}
            </Box>
          ))}
        </HStack>
      </VStack>
    );
  }
  if (dataDetail?.monthly) {
    return (
      <VStack mb="4">
        <Text fontSize="sm">Todos los meses en los días: </Text>
        <HStack>
          {dataDetail?.monthly_periodicity_days.map(({ value }, i) => (
            <Box
              key={`monthly-${i}`}
              py="1"
              px="2"
              rounded="xl"
              border="1px"
              borderColor={"gray.300"}
              fontSize="xs"
            >
              {value}
            </Box>
          ))}
        </HStack>
      </VStack>
    );
  }
  if (dataDetail?.yearly) {
    return (
      <VStack mb="4">
        <Text fontSize="sm">
          Se repite cada año por cada{" "}
          {dataDetail?.yearly_each_periodicity_months} mes(es).
        </Text>
      </VStack>
    );
  }
  return null;
};

const TareaEntregaTable = ({ dataDetail, setLocalNextDate }) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const calendar = useMemo(() => dataDetail.calendar, [dataDetail]);

  useEffect(() => {
    if (setLocalNextDate && dataDetail?.next_date)
      setLocalNextDate(dayjs(dataDetail?.next_date));
  }, [dataDetail?.next_date, setLocalNextDate]);

  return (
    <HStack>
      <Text>
        {calendar.length > 0 && dataDetail?.next_date
          ? dayjs.utc(dataDetail?.next_date).format("DD/MM/YYYY")
          : ""}
      </Text>
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        placement="right"
        closeOnBlur={false}
      >
        <PopoverTrigger>
          <IconButton size="sm" icon={<InfoIcon />} />
        </PopoverTrigger>
        <PopoverContent p={5}>
          <FocusLock returnFocus persistentFocus={false}>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopCalendarDetail dataDetail={dataDetail} />
          </FocusLock>
        </PopoverContent>
      </Popover>
    </HStack>
  );
};

export default TareaEntregaTable;
