import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BACKEND_SERVER, isJson } from "../constans/utils";
import { get } from "lodash";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const heraApi = createApi({
  reducerPath: "heraApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BACKEND_SERVER,

    prepareHeaders: async (headers, { endpoint }) => {
      if (endpoint !== "setFileToProcess" && endpoint !== "setXlsxUsers") {
        headers.set("Content-Type", "application/json");
      }

      const isjson = isJson(localStorage.getItem("session") || "");
      if (isjson) headers.set("Authorization", `Bearer ${isjson.access_token}`);
      return headers;
    },
  }),
  tagTypes: [
    "Inactivos",
    "Activities",
    "Analytics",
    "Estatus",
    "Users",
    "Process",
    "Comments",
    "Areas",
    "Notifications",
  ],
  endpoints: (builder) => ({
    getActivitiesInactive: builder.query({
      query: () => {
        return {
          url: `v1/activity/inactive`,
          method: "GET",
        };
      },
      providesTags: (result, _error) => (result ? [{ type: "Inactivos" }] : []),
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    getActivities: builder.query({
      query: (queryString) => {
        const query = queryString ? `?${queryString}` : "";
        return {
          url: `v1/activity${query}`,
          method: "GET",
        };
      },
      providesTags: (result, _error) =>
        result ? [{ type: "Activities" }] : [],
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
      transformResponse: (response) => {
        return response.reduce((acc, act) => {
          const calendar = get(act, "calendar", []);
          if (acc.finish_date && calendar.length > 0) {
            const nextDate = dayjs.utc(act.next_date);
            if (nextDate) {
              acc.push(act);
            }
          }
          acc.push(act);

          return acc;
        }, []);
      },
    }),
    getProcess: builder.query({
      query: () => {
        return {
          url: `v1/activity/process`,
          method: "GET",
        };
      },
      providesTags: (result, _error) => (result ? [{ type: "Process" }] : []),
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    getAnalytics: builder.query({
      query: () => {
        return {
          url: `v1/activity/analytics`,
          method: "GET",
        };
      },
      providesTags: (result, _error) => (result ? [{ type: "Analytics" }] : []),
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    getActivitiesCalendar: builder.query({
      query: () => {
        return {
          url: `v1/activity/calendar`,
          method: "GET",
        };
      },
      providesTags: (result, _error) =>
        result ? [{ type: "Activities" }] : [],
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    getEstatus: builder.query({
      query: () => {
        return {
          url: `v1/estatus`,
          method: "GET",
        };
      },
      providesTags: (result, _error) => (result ? [{ type: "Estatus" }] : []),
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    getUsers: builder.query({
      query: (string) => {
        return {
          url: `v1/users/all/${string}`,
          method: "GET",
        };
      },
      providesTags: (result, _error) => (result ? [{ type: "Users" }] : []),
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    getComments: builder.query({
      query: (uuid) => {
        return {
          url: `v1/comments/${uuid}`,
          method: "GET",
        };
      },
      providesTags: (result, _error) => (result ? [{ type: "Comments" }] : []),
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    setNewActivity: builder.mutation({
      query: (payload) => {
        return {
          url: `v1/activity`,
          method: "POST",
          body: payload,
        };
      },
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    updateActivity: builder.mutation({
      query: ({ payload, uuid }) => {
        return {
          url: `v1/activity/${uuid}`,
          method: "PUT",
          body: payload,
        };
      },
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    updateWork: builder.mutation({
      query: ({ payload, uuid }) => {
        return {
          url: `v1/activity/activity/${uuid}`,
          method: "PUT",
          body: payload,
        };
      },
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    deleteActivity: builder.mutation({
      query: (uuid) => {
        return {
          url: `v1/activity/${uuid}`,
          method: "DELETE",
        };
      },
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    setFileToProcess: builder.mutation({
      query: (payload) => {
        const formData = new FormData();
        formData.append("file", payload.file);
        return {
          url: `v1/activity/file/${payload.uuid}`,
          method: "POST",
          body: formData,
        };
      },
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    setCheckToUser: builder.mutation({
      query: ({ uuid, payload }) => {
        return {
          url: `v1/activity/usercheck/${uuid}`,
          method: "POST",
          body: payload,
        };
      },
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    setCheckToSupervisor: builder.mutation({
      query: ({ uuid, payload }) => {
        return {
          url: `v1/activity/admincheck/${uuid}`,
          method: "POST",
          body: payload,
        };
      },
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    setStatusSetter: builder.mutation({
      query: ({ uuid, payload }) => {
        return {
          url: `v1/activity/statussetter/${uuid}`,
          method: "POST",
          body: payload,
        };
      },
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    setNewUser: builder.mutation({
      query: (payload) => {
        return {
          url: `v1/auth/user`,
          method: "POST",
          body: payload,
        };
      },
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    updateUser: builder.mutation({
      query: ({ id, payload }) => {
        return {
          url: `v1/users/update/${id}`,
          method: "PUT",
          body: payload,
        };
      },
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    deleteUser: builder.mutation({
      query: (userId) => {
        return {
          url: `v1/users/delete/${userId}`,
          method: "DELETE",
        };
      },
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    setComment: builder.mutation({
      query: ({ uuid, payload }) => {
        return {
          url: `v1/comments/${uuid}`,
          method: "POST",
          body: payload,
        };
      },
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    deleteComment: builder.mutation({
      query: ({ uuid, id }) => {
        return {
          url: `v1/comments/${uuid}/${id}`,
          method: "DELETE",
        };
      },
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    getAreas: builder.query({
      query: () => {
        return {
          url: `v1/areas`,
          method: "GET",
        };
      },
      providesTags: (result, _error) => (result ? [{ type: "Areas" }] : []),
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    setArea: builder.mutation({
      query: (payload) => {
        return {
          url: `v1/areas`,
          method: "POST",
          body: payload,
        };
      },
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    updateArea: builder.mutation({
      query: ({ payload, id }) => {
        return {
          url: `v1/areas/${id}`,
          method: "PUT",
          body: payload,
        };
      },
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    updatePassword: builder.mutation({
      query: ({ payload, id }) => {
        return {
          url: `v1/users/update/password/${id}`,
          method: "PUT",
          body: payload,
        };
      },
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    deleteArea: builder.mutation({
      query: (id) => {
        return {
          url: `v1/areas/${id}`,
          method: "DELETE",
        };
      },
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    getAreasByUser: builder.query({
      query: (id) => {
        return {
          url: `v1/users/areas/${id}`,
          method: "GET",
        };
      },
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    setXlsxUsers: builder.mutation({
      query: (payload) => {
        const formData = new FormData();
        formData.append("file", payload.file);
        return {
          url: `v1/users/file`,
          method: "POST",
          body: formData,
        };
      },
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    getNotifications: builder.query({
      query: (id) => {
        return {
          url: `v1/notifications/${id}`,
          method: "GET",
        };
      },
      providesTags: (result, _error) =>
        result ? [{ type: "Notifications" }] : [],
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    updateNotification: builder.mutation({
      query: ({ payload, id }) => {
        return {
          url: `v1/notifications/${id}`,
          method: "PUT",
          body: payload,
        };
      },
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    deleteNotification: builder.mutation({
      query: (id) => {
        return {
          url: `v1/notifications/${id}`,
          method: "DELETE",
        };
      },
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    newLike: builder.mutation({
      query: (id) => {
        return {
          url: `v1/comments/like/${id}`,
          method: "POST",
        };
      },
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
  }),
});

export const {
  useLazyGetActivitiesInactiveQuery,
  useLazyGetActivitiesQuery,
  useGetAnalyticsQuery,
  useGetActivitiesCalendarQuery,
  useGetEstatusQuery,
  useGetProcessQuery,
  useLazyGetProcessQuery,
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useLazyGetCommentsQuery,
  useSetStatusSetterMutation,
  useSetNewActivityMutation,
  useUpdateActivityMutation,
  useDeleteActivityMutation,
  useSetCheckToUserMutation,
  useSetCheckToSupervisorMutation,
  useSetFileToProcessMutation,
  useSetNewUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useSetCommentMutation,
  useDeleteCommentMutation,
  useLazyGetAreasQuery,
  useSetAreaMutation,
  useUpdateAreaMutation,
  useDeleteAreaMutation,
  useLazyGetAreasByUserQuery,
  useGetAreasByUserQuery,
  useSetXlsxUsersMutation,
  useDeleteNotificationMutation,
  useUpdateNotificationMutation,
  useGetNotificationsQuery,
  useLazyGetNotificationsQuery,
  useNewLikeMutation,
  useUpdatePasswordMutation,
  useUpdateWorkMutation,
} = heraApi;
