import { useReducer, useEffect, useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
  Textarea,
} from "@chakra-ui/react";
import { cloneDeep, set } from "lodash";

import { useSetAreaMutation, useUpdateAreaMutation } from "../../store/heraApi";

const STATE = {
  name: "",
  description: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INPUT":
      const cloned = cloneDeep(state);
      set(cloned, action.key, action.value);
      return cloned;
    case "RESET":
      const clonedState = cloneDeep(STATE);
      return clonedState;
    default:
      return state;
  }
};

const AreaForm = ({ userSelected }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [modelObject, dispatch] = useReducer(reducer, STATE);
  const [setNewUser, { isLoading, isError, isSuccess }] = useSetAreaMutation();
  const [
    updateArea,
    {
      isLoading: isUpdatingLoading,
      isError: isUpdatingError,
      isSuccess: isUpdatingSuccess,
    },
  ] = useUpdateAreaMutation();
  const toast = useToast();

  useEffect(() => {
    if (isError || isUpdatingError)
      toast({
        title: "Ooops!",
        description:
          "Hubo un error al querer guardar los datos, intenta de nuevo",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
  }, [isError, isUpdatingError, toast]);

  useEffect(() => {
    if (isSuccess || isUpdatingSuccess) {
      toast({
        title: "¡Exito!",
        description: "Se realizó la acción con éxito",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      dispatch({ type: "RESET" });
    }
  }, [isSuccess, isUpdatingSuccess, toast]);

  useEffect(() => {
    if (userSelected?.id) {
      setIsEditing(true);
      Object.keys(userSelected).forEach((key) => {
        handlerReducer(userSelected[key], key);
      });
    }
  }, [userSelected, userSelected?.id]);

  const handlerReducer = (value, key) => {
    dispatch({ type: "INPUT", value, key });
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (!isEditing) setNewUser(modelObject).unwrap();
        else if (isEditing)
          updateArea({ id: modelObject.id, payload: modelObject }).unwrap();
      }}
    >
      <FormControl mt="4" mb="2" isRequired>
        <FormLabel>Nombre del área</FormLabel>
        <Input
          type="text"
          value={modelObject.name}
          onInput={(e) => handlerReducer(e.target.value, "name")}
        />
      </FormControl>
      <FormControl mt="4" mb="2">
        <FormLabel>Descripción</FormLabel>
        <Textarea
          type="text"
          value={modelObject.description}
          onInput={(e) => handlerReducer(e.target.value, "description")}
        />
      </FormControl>

      <Button
        type="submit"
        isLoading={isLoading || isUpdatingLoading}
        loadingText="Enviando información"
        mt="6"
        bg="brand.blue1"
        color="white"
      >
        Enviar información
      </Button>
    </form>
  );
};

export default AreaForm;
