import { VStack, Text, HStack, Box } from "@chakra-ui/react";

const TareaDatePer = ({ dataDetail }) => {
  if (dataDetail?.daily) {
    return (
      <VStack mb="4">
        <Text fontWeight={800} fontSize="1.3em">
          Se repite todos los días
        </Text>
      </VStack>
    );
  }

  if (dataDetail?.weekly) {
    return (
      <VStack mb="4">
        <Text fontWeight={800} fontSize="1.3em">
          Todas las semanas, los días:{" "}
        </Text>
        <HStack>
          {dataDetail?.periodicity_weekly.map(({ label }, i) => (
            <Box
              key={`weekly-${i}`}
              py="2"
              px="4"
              rounded="xl"
              border="1px"
              borderColor={"gray.300"}
            >
              {label}
            </Box>
          ))}
        </HStack>
      </VStack>
    );
  }
  if (dataDetail?.monthly) {
    return (
      <VStack mb="4">
        <Text fontWeight={800} fontSize="1.3em">
          Todos los meses en los días:{" "}
        </Text>
        <HStack>
          {dataDetail?.monthly_periodicity_days.map(({ value }, i) => (
            <Box
              key={`monthly-${i}`}
              py="2"
              px="4"
              rounded="xl"
              border="1px"
              borderColor={"gray.300"}
            >
              {value}
            </Box>
          ))}
        </HStack>
      </VStack>
    );
  }
  if (dataDetail?.yearly) {
    return (
      <VStack mb="4">
        <Text fontWeight={800} fontSize="1.3em">
          Se repite cada año por cada{" "}
          {dataDetail?.yearly_each_periodicity_months} mes(es).
        </Text>
      </VStack>
    );
  }
  return null;
};

export default TareaDatePer;
