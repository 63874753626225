import {
  Box,
  HStack,
  Flex,
  Text,
  VStack,
  Heading,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Stack,
  StackDivider,
  SimpleGrid,
  useToast,
  Spinner,
} from "@chakra-ui/react";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import TareaCard from "../components/TareaCard";
import HeaderPage from "../components/HeaderPage";
import {
  useGetAnalyticsQuery,
  useLazyGetActivitiesQuery,
} from "../store/heraApi";
import { useEffect, useState } from "react";
import { tareasParaHoy, tareasPorTiempo } from "../constans/utils";

ChartJS.register(ArcElement, Tooltip, Legend);

const Dashboard = () => {
  const [dataMapped, setDataMapped] = useState({});
  const [refetch, { data, isError, isLoading }] = useLazyGetActivitiesQuery();
  const { data: dataAnalytics } = useGetAnalyticsQuery();
  const toast = useToast;

  const colorGraph = (type) => {
    switch (type) {
      case "pendiente":
        return "#707579";
      case "curso":
        return "#FFA800";
      case "realizado":
        return "#85CB4E";
      case "retraso":
        return "#F81010";
      default:
        return "";
    }
  };

  useEffect(() => {
    if (dataAnalytics) {
      setDataMapped(
        Object.keys(dataAnalytics).reduce((acc, type) => {
          if (type !== "total") {
            const value = dataAnalytics[type];
            acc[type] = {
              labels: [],
              datasets: [
                {
                  data: [value, dataAnalytics.total],
                  backgroundColor: [colorGraph(type), "#EEEEEE"],
                  borderColor: [],
                  borderWidth: 0,
                },
              ],
            };
          }
          return acc;
        }, {})
      );
    }
  }, [dataAnalytics]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isError)
      toast({
        title: "Ooops!",
        description:
          "No se pudo obtener las tareas, verifica que tengas una sesión.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
  }, [isError, toast]);

  const overallData = data ?? [];

  const todayData = tareasParaHoy(overallData);
  const weeklyData = tareasPorTiempo(overallData, "week");
  const monthlyData = tareasPorTiempo(overallData, "month");

  if (isLoading) {
    return (
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    );
  }

  return (
    <>
      <HeaderPage />
      <Flex
        minWidth={{ md: "inherit", base: "inherit" }}
        mt="36"
        direction={{ lg: "row", base: "column" }}
        w="full"
        p={{ lg: 12, base: 4 }}
      >
        <Box flex="1">
          <VStack p="4" rounded="xl" bg="white" shadow={"lg"}>
            <Tabs w="full">
              <HStack
                borderBottomWidth="1px"
                borderBottomColor={"gray.200"}
                justifyContent={"start"}
                w="full"
                flex="1"
              >
                <Heading size="sm" fontWeight={900} mr="auto">
                  Mis Tareas
                </Heading>
                <Box ml="auto">
                  <TabList>
                    <Tab>Hoy</Tab>
                    <Tab>Semana</Tab>
                    <Tab>Mes</Tab>
                  </TabList>
                </Box>
              </HStack>
              <TabPanels>
                <TabPanel>
                  <Stack
                    spacing="4"
                    divider={<StackDivider borderColor="gray.200" />}
                  >
                    {todayData.map((activity) => (
                      <TareaCard {...activity} key={`today-${activity.uuid}`} />
                    ))}
                  </Stack>
                </TabPanel>
                <TabPanel>
                  <Stack
                    spacing="4"
                    divider={<StackDivider borderColor="gray.200" />}
                  >
                    {weeklyData.map((activity) => (
                      <TareaCard {...activity} key={`week-${activity.uuid}`} />
                    ))}
                  </Stack>
                </TabPanel>
                <TabPanel>
                  <Stack
                    spacing="4"
                    divider={<StackDivider borderColor="gray.200" />}
                  >
                    {monthlyData.map((activity) => (
                      <TareaCard {...activity} key={`month-${activity.uuid}`} />
                    ))}
                  </Stack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </VStack>
        </Box>

        <Box p="8" flex="1">
          <SimpleGrid columns={[1, null, 2]} spacing="12">
            <Box bg="white" shadow="sm" rounded="xl">
              <HStack>
                <VStack alignItems={"start"} p="6">
                  <Heading fontWeight={900} size="md">
                    {dataAnalytics?.curso}
                  </Heading>
                  <Text sm="xs">Tareas en curso</Text>
                </VStack>
                <Box w="20" h="20" ml="auto" mr="6">
                  {dataMapped?.curso && (
                    <Doughnut data={dataMapped?.curso} responsive />
                  )}
                </Box>
              </HStack>
            </Box>
            <Box bg="white" shadow="sm" rounded="xl">
              <HStack>
                <VStack alignItems={"start"} p="6">
                  <Heading fontWeight={900} size="md">
                    {dataAnalytics?.pendiente}
                  </Heading>
                  <Text sm="xs">Tareas pendientes</Text>
                </VStack>
                <Box w="20" h="20" ml="auto" mr="6">
                  {dataMapped?.pendiente && (
                    <Doughnut data={dataMapped?.pendiente} responsive />
                  )}
                </Box>
              </HStack>
            </Box>
            <Box bg="white" shadow="sm" rounded="xl">
              <HStack>
                <VStack alignItems={"start"} p="6">
                  <Heading fontWeight={900} size="md">
                    {dataAnalytics?.realizado}
                  </Heading>
                  <Text sm="xs">Tareas realizadas</Text>
                </VStack>
                <Box w="20" h="20" ml="auto" mr="6">
                  {dataMapped?.realizado && (
                    <Doughnut data={dataMapped?.realizado} responsive />
                  )}
                </Box>
              </HStack>
            </Box>
            <Box bg="white" shadow="sm" rounded="xl">
              <HStack>
                <VStack alignItems={"start"} p="6">
                  <Heading fontWeight={900} size="md">
                    {dataAnalytics?.retraso}
                  </Heading>
                  <Text sm="xs">Tareas con retraso</Text>
                </VStack>
                <Box w="20" h="20" ml="auto" mr="6">
                  {dataMapped?.retraso && (
                    <Doughnut data={dataMapped?.retraso} responsive />
                  )}
                </Box>
              </HStack>
            </Box>
          </SimpleGrid>
        </Box>
      </Flex>
    </>
  );
};

export default Dashboard;
