import { createBrowserRouter } from "react-router-dom";

import ErrorPage from "../pages/ErrorPage";
import Login from "../pages/Login";

import DefaultLayout from "../layouts/Default";

import Dashboard from "../pages/Dashboard";
import Agenda from "../pages/Agenda";
import Tareas from "../pages/Tareas";
import Procesos from "../pages/Procesos";
import Documentos from "../pages/Documentos";
import Usuarios from "../pages/Usuarios";
import Perfil from "../pages/Perfil";
import Areas from "../pages/Areas";
import Delayed from "../pages/Delayed";

export const router = createBrowserRouter([
  {
    path: "inicio",
    element: <DefaultLayout />,
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
    ],
  },
  {
    path: "/",
    children: [
      {
        index: true,
        element: <Login />,
      },
    ],
  },
  {
    path: "agenda",
    element: <DefaultLayout />,
    children: [
      {
        index: true,
        element: <Agenda />,
      },
    ],
  },
  {
    path: "tareas",
    element: <DefaultLayout />,
    children: [
      {
        index: true,
        element: <Tareas />,
      },
    ],
  },
  {
    path: "inactivas",
    element: <DefaultLayout />,
    children: [
      {
        index: true,
        element: <Delayed />,
      },
    ],
  },
  {
    path: "procesos",
    element: <DefaultLayout />,
    children: [
      {
        index: true,
        element: <Procesos />,
      },
    ],
  },
  {
    path: "documentos",
    element: <DefaultLayout />,
    children: [
      {
        index: true,
        element: <Documentos />,
      },
    ],
  },
  {
    path: "usuarios",
    element: <DefaultLayout />,
    children: [
      {
        index: true,
        element: <Usuarios />,
      },
    ],
  },
  {
    path: "areas",
    element: <DefaultLayout />,
    children: [
      {
        index: true,
        element: <Areas />,
      },
    ],
  },
  {
    path: "perfil",
    element: <DefaultLayout />,
    children: [
      {
        index: true,
        element: <Perfil />,
      },
    ],
  },
  // {
  //   path: "levantamientos",
  //   element: <DefaultLayout />,
  //   children: [
  //     {
  //       index: true,
  //       element: <Levantamiento />,
  //     },
  //   ],
  // },
  // {
  //   path: "clientes",
  //   element: <DefaultLayout />,
  //   children: [
  //     {
  //       index: true,
  //       element: <Clientes />,
  //     },
  //   ],
  // },
  {
    path: "*",
    element: <ErrorPage />,
  },
]);
