import {
  Box,
  HStack,
  IconButton,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Flex,
  VStack,
  TabIndicator,
  Menu,
  Button,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  useToast,
  Spinner,
  WrapItem,
  Avatar,
  AlertDialog,
  useDisclosure,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Tooltip,
  Textarea,
  Divider,
  Heading,
} from "@chakra-ui/react";

import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";

import { filter, pdficon } from "../assets/icons/icons";
import {
  ROLES,
  TOAST_TIME,
  TYPES,
  TYPES_SP,
  bodySelection,
  tareasParaHoy,
  tareasPorTiempo,
} from "../constans/utils";
import {
  useDeleteActivityMutation,
  useDeleteCommentMutation,
  useGetEstatusQuery,
  useLazyGetActivitiesQuery,
  useLazyGetCommentsQuery,
  useSetCheckToSupervisorMutation,
  useSetCheckToUserMutation,
  useSetCommentMutation,
  useSetStatusSetterMutation,
} from "../store/heraApi";
import ActivityForm from "../components/forms/ActivityForm";
import TareaTableRow from "../components/TareaTableRow";
import { useGetToken } from "../hooks/useAuth";

import Markdown from "react-markdown";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import CommentCard from "../components/CommentCard";
import Api from "../constans/api";
import { get, orderBy } from "lodash";
import TareaHistorical from "../components/TareaHistorical";
import TareaDatePer from "../components/TareaDatePer";

dayjs.extend(isSameOrBefore);

const FilterIcon = () => {
  return <Image src={filter} />;
};

const HeaderTab = () => {
  return (
    <HStack w="full" mb="12" mt="6">
      <HStack mr="6">
        <Box h="27px" w="8px" rounded="xl" mr="2" bg="brand.blue2" />
        <Text fontWeight={800} color="brand.blue2" fontSize="sm">
          Tareas operativas
        </Text>
      </HStack>
      <HStack>
        <Box h="27px" w="8px" rounded="xl" mr="2" bg="brand.green1" />
        <Text fontWeight={800} color="brand.green1" fontSize="sm">
          Tareas programadas
        </Text>
      </HStack>
    </HStack>
  );
};

const TableHeads = ({ user }) => {
  return (
    <Thead>
      <Tr>
        <Th color="brand.blue1">Tarea</Th>
        <Th color="brand.blue1">Fecha de entrega</Th>
        <Th color="brand.blue1">Status</Th>
        <Th color="brand.blue1">Check usuario</Th>
        {Array.isArray(user?.rol) && user?.rol?.join("") === ROLES.USER && (
          <Th color="brand.blue1">Asignado(s) / Check supervisor</Th>
        )}
        {Array.isArray(user?.rol) && user?.rol?.join("") !== ROLES.USER && (
          <Th color="brand.blue1">Asignado(s) / Check supervisor</Th>
        )}
      </Tr>
    </Thead>
  );
};

const Tareas = () => {
  const [comment, setComment] = useState("");
  const [dataDetail, setDataDetail] = useState({});
  const [newTarea, setNewTarea] = useState(false);
  const [inEditionMode, setInEditionMode] = useState(false);
  const [filter, setFilter] = useState("S");
  const [message, setMessage] = useState("");
  const [alertCheckValue, setAlertCheckValue] = useState("");
  const [alertStatusValue, setAlertStatusValue] = useState("");
  const [checkTypeUser, setCheckTypeUser] = useState("");
  const [checkPayload, setCheckPayload] = useState({});
  const cancelRef = useRef();
  const cancelDetailRef = useRef();

  const { user, token } = useGetToken();
  const [refetch, { data, isError, isLoading }] = useLazyGetActivitiesQuery();
  const [fetchComments, { data: dataComments }] = useLazyGetCommentsQuery();
  const [
    fetchComment,
    {
      isError: isCommentError,
      isSuccess: isCommentSuccess,
      isLoading: isCommentLoading,
    },
  ] = useSetCommentMutation();
  const [
    commentDeleterFetcher,
    { isSuccess: isCommentDeleted, isError: isCommentDeletedError },
  ] = useDeleteCommentMutation();
  const [
    setStatusSetter,
    {
      isError: isStatusError,
      isLoading: isStatusLoading,
      isSuccess: isStatusSuccess,
    },
  ] = useSetStatusSetterMutation();
  const [
    setActivityDeleted,
    { isError: isActError, isLoading: isActLoading, isSuccess: isActSuccess },
  ] = useDeleteActivityMutation();
  const [
    setUserCheck,
    {
      isError: isUserCheckError,
      isLoading: isUserCheckLoading,
      isSuccess: isUserCheckSuccess,
    },
  ] = useSetCheckToUserMutation();
  const [
    setAdminCheck,
    {
      isError: isAdminCheckError,
      isLoading: isAdminCheckLoading,
      isSuccess: isAdminCheckSuccess,
    },
  ] = useSetCheckToSupervisorMutation();
  const { data: dataStatus } = useGetEstatusQuery();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDetailOpen,
    onOpen: onDetailOpen,
    onClose: onDetailClose,
  } = useDisclosure();

  const newTareaHandler = () => {
    setNewTarea(!newTarea);
    setInEditionMode(false);
    bodySelection();
  };

  useEffect(() => {
    if (dataDetail?.uuid) {
      fetchComments(dataDetail?.uuid).unwrap();
    }
  }, [fetchComments, dataDetail?.uuid, isCommentSuccess, isCommentDeleted]);

  useEffect(() => {
    const valueFromTypes = TYPES_SP[filter];
    const params = new URLSearchParams({ type: valueFromTypes });
    refetch(valueFromTypes ? params.toString() : "").unwrap();
  }, [
    filter,
    refetch,
    newTarea,
    // Cuando se hacen las ediciones exitosas
    isActSuccess,
    isStatusSuccess,
    isUserCheckSuccess,
    isAdminCheckSuccess,
  ]);

  useEffect(() => {
    if (
      isUserCheckError ||
      isAdminCheckError ||
      isStatusError ||
      isCommentError ||
      isCommentDeletedError ||
      isActError
    )
      toast({
        title: "Ooops!",
        description: "Ocurrió un error al realizar la acción",
        status: "error",
        duration: TOAST_TIME,
        isClosable: true,
      });
  }, [
    isUserCheckError,
    isAdminCheckError,
    isStatusError,
    isCommentError,
    isCommentDeletedError,
    isActError,
    toast,
  ]);

  useEffect(() => {
    if (
      isUserCheckSuccess ||
      isAdminCheckSuccess ||
      isStatusSuccess ||
      isActSuccess
    )
      toast({
        title: "¡Éxito!",
        description: "Se realizó la acción con éxito.",
        status: "success",
        duration: TOAST_TIME,
        isClosable: true,
      });
  }, [
    isUserCheckSuccess,
    isAdminCheckSuccess,
    isStatusSuccess,
    isActSuccess,
    toast,
  ]);

  useEffect(() => {
    if (isError)
      toast({
        title: "Ooops!",
        description:
          "No se pudo obtener las tareas, verifica que tengas una sesión.",
        status: "error",
        duration: TOAST_TIME,
        isClosable: true,
      });
  }, [isError, toast]);

  useEffect(() => {
    if (!inEditionMode) setDataDetail({});
  }, [inEditionMode]);

  const statusHandlerSelection = (id, uuid) => {
    setAlertCheckValue("");
    setAlertStatusValue({ id, uuid });
    setMessage("¿Estas segur@ de modificar el estatus de la tarea?");
    onOpen();
  };

  const userOnCheck = (typeUser, uuid, calendarObject) => {
    setAlertStatusValue("");
    setAlertCheckValue(uuid);
    setCheckTypeUser(typeUser);
    setCheckPayload(calendarObject);
    setMessage("Al completar la tarea no se podrá revertir esta acción.");
    onOpen();
  };

  const deleteHandler = async () => {
    if (checkTypeUser === ROLES.USER) {
      await setUserCheck({
        uuid: alertCheckValue,
        payload: checkPayload,
      }).unwrap();
    } else if (
      checkTypeUser === ROLES.ADMIN ||
      checkTypeUser === ROLES.SUPERVISOR
    ) {
      await setAdminCheck({
        uuid: alertCheckValue,
        payload: checkPayload,
      }).unwrap();
    }
    closeAlertHandler();
  };

  const statusFetcher = async () => {
    const { uuid, id } = alertStatusValue;
    await setStatusSetter({ uuid, payload: { status: id } }).unwrap();
    closeAlertHandler();
  };

  const closeAlertHandler = () => {
    onClose();
    setAlertCheckValue("");
    setAlertStatusValue("");
    setCheckTypeUser("");
    setCheckPayload({});
  };

  const overallData = data ?? [];

  const todayData = tareasParaHoy(overallData);
  const weeklyData = tareasPorTiempo(overallData, "week");
  const allTareas = orderBy(
    overallData,
    [
      (activity) => {
        const nextDate = dayjs.utc(activity.next_date);
        return nextDate;
      },
      "daily",
      "weekly",
      "monthly",
      "yearly",
    ],
    ["asc", "desc", "desc", "desc", "desc"]
  );

  return (
    <>
      <AlertDialog
        isOpen={isDetailOpen}
        leastDestructiveRef={cancelDetailRef}
        onClose={() => {
          onDetailClose();
          setDataDetail({});
          setInEditionMode(false);
        }}
        size={"4xl"}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {dataDetail?.name}
            </AlertDialogHeader>

            <AlertDialogBody>
              <HStack>
                {dataDetail?.start_date && (
                  <Box pr="2" borderRight={"1px solid"}>
                    Fecha inicio:{" "}
                    {dayjs.utc(dataDetail?.start_date).format("DD/MM/YYYY")}
                  </Box>
                )}
                {dataDetail?.finish_date && (
                  <Box
                    textColor={
                      dayjs
                        .utc(dataDetail?.finish_date)
                        .isSameOrBefore(Date.now(), "day")
                        ? "red.300"
                        : "black"
                    }
                  >
                    Fecha fin:{" "}
                    {dayjs.utc(dataDetail?.finish_date).format("DD/MM/YYYY")}
                  </Box>
                )}
              </HStack>
              {dataDetail?.description && (
                <Box
                  maxW="4xl"
                  w="full"
                  mx="auto"
                  borderWidth="1px"
                  bordercolor="gray.200"
                  rounded="lg"
                  mb="12"
                  mt="4"
                  px="8"
                  py="6"
                >
                  <Markdown>{dataDetail?.description}</Markdown>
                </Box>
              )}

              <TareaDatePer dataDetail={dataDetail} />

              <HStack w="full" justifyContent={"center"}>
                {(dataDetail?.users_assigned ?? []).length > 0 && (
                  <VStack m="2">
                    <Text>Asignados: </Text>
                    <HStack>
                      {(dataDetail?.users_assigned ?? [])
                        .map(({ label, value }) => {
                          const [name, username] = label.split(" - ");
                          return {
                            name,
                            username,
                            id: value,
                          };
                        })
                        .map(({ id, name }) => (
                          <Box key={`user-${id}-${name}`}>
                            <Tooltip
                              key={`operative-${id}-${name}`}
                              label={name}
                            >
                              <WrapItem>
                                <Avatar
                                  size="sm"
                                  name={name}
                                  borderColor="brand.blue1"
                                  borderWidth={"1px"}
                                />
                              </WrapItem>
                            </Tooltip>
                          </Box>
                        ))}
                    </HStack>
                  </VStack>
                )}

                <Flex justifyContent={"center"} my="4" mx="2">
                  {dataDetail?.uuid && dataDetail?.file_url && (
                    <Button
                      variant="link"
                      colorScheme="brand.blue.500"
                      onClick={async () => {
                        const api = new Api();
                        const blob = await api.http.getStream(
                          `v1/activity/download/files/${dataDetail?.uuid}`,
                          token
                        );
                        const urlObject = URL.createObjectURL(blob);
                        // Abrir el PDF en un nuevo tab del navegador
                        window.open(urlObject, "_blank");
                      }}
                    >
                      <HStack
                        border="1px solid"
                        rounded="xl"
                        p="3"
                        borderColor="gray.200"
                      >
                        <Image src={pdficon} />
                        <Text>
                          {get(
                            dataDetail,
                            "additional.file.name",
                            dataDetail?.file_url
                          )}
                        </Text>
                      </HStack>
                    </Button>
                  )}
                </Flex>
              </HStack>

              <Box>
                <Heading as="h6" size="md" mb="2">
                  Checks históricos
                </Heading>
                <TareaHistorical
                  type={dataDetail?.type}
                  historical={dataDetail.checks ?? []}
                />
              </Box>

              <Divider my="8" />

              <VStack maxW="4xl" w="full" mx="auto" my="4">
                {dataDetail?.type === TYPES.OPERATIVE &&
                  !user?.rol?.includes(ROLES.USER) && (
                    <>
                      <Textarea
                        value={comment}
                        onInput={(e) => setComment(e.target.value)}
                      />
                      <Button
                        variant="outline"
                        color="brand.blue1"
                        ml="auto"
                        fontWeight={400}
                        borderWidth={"1px"}
                        borderColor={"brand.blue1"}
                        loadingText="Enviando información"
                        isLoading={isCommentLoading}
                        onClick={() => {
                          if (comment) {
                            fetchComment({
                              uuid: dataDetail.uuid,
                              payload: { comment },
                            });
                          }
                        }}
                      >
                        Comentar
                      </Button>
                    </>
                  )}
                {(dataComments ?? []).map((comment, key) => (
                  <CommentCard
                    key={`comment-${key}`}
                    {...comment}
                    user={user}
                    fetcher={commentDeleterFetcher}
                  />
                ))}
              </VStack>
            </AlertDialogBody>

            <AlertDialogFooter>
              {!user?.rol?.includes("user") && (
                <Button
                  bgColor="red.400"
                  color="white"
                  onClick={() => {
                    onDetailClose();
                    setActivityDeleted(dataDetail.uuid).unwrap();
                  }}
                  mr="4"
                  isLoading={isActLoading}
                  loadingText="Cargando"
                >
                  Eliminar
                </Button>
              )}
              {!user?.rol?.includes("user") && (
                <Button
                  onClick={() => {
                    onDetailClose();
                    setInEditionMode(true);
                    setNewTarea(true);
                  }}
                  mr="4"
                  loadingText="Cargando"
                >
                  Editar
                </Button>
              )}
              <Button
                ref={cancelRef}
                onClick={() => {
                  onDetailClose();
                  setDataDetail({});
                  setInEditionMode(false);
                }}
              >
                Cerrar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={closeAlertHandler}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              ¡Espera!
            </AlertDialogHeader>

            <AlertDialogBody>{message}</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={closeAlertHandler}>
                Cancelar
              </Button>
              <Button
                bgColor="brand.blue1"
                color="white"
                onClick={() => {
                  if (alertCheckValue) {
                    deleteHandler();
                  } else if (alertStatusValue) {
                    statusFetcher();
                  }
                }}
                ml={3}
                isLoading={
                  isUserCheckLoading || isAdminCheckLoading || isStatusLoading
                }
              >
                Aceptar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Drawer
        isOpen={newTarea}
        placement="right"
        onClose={newTareaHandler}
        size="lg"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader fontWeight={900}>Nueva Tarea</DrawerHeader>

          <DrawerBody>
            <ActivityForm
              inEditionMode={inEditionMode}
              user={user}
              dataDetail={dataDetail}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Box
        minWidth={{ lg: "max-content", md: "inherit", base: "inherit" }}
        direction={{ lg: "row", base: "column" }}
        w="full"
        p={{ lg: 8, base: 4 }}
      >
        <VStack p="4" rounded="xl" bg="none">
          <Flex w="full" mb="6" direction={{ base: "column", md: "row" }}>
            <HStack
              p="6"
              rounded="xl"
              bg="white"
              minW={{ base: "full", md: "xs" }}
              mr="auto"
            >
              <WrapItem>
                <Avatar
                  size="lg"
                  name={user?.name}
                  borderColor="brand.blue1"
                  borderWidth={"1px"}
                  mr="2"
                />
              </WrapItem>
              <VStack alignItems={"start"} mr="auto">
                <Text fontSize="18" fontWeight={900} mb="0">
                  {user?.name}
                </Text>
                <Text
                  fontSize="xs"
                  fontWeight={100}
                  textTransform={"uppercase"}
                >
                  {user?.rol?.join("")}
                </Text>
              </VStack>
            </HStack>
            <Button
              mt={{ base: "4", md: "0" }}
              variant="outline"
              colorScheme="brand.blue1"
              ml="auto"
              fontWeight={400}
              onClick={newTareaHandler}
            >
              Agregar Tarea
            </Button>
          </Flex>

          {isLoading ? (
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          ) : (
            <Tabs w="full" size="sm" position="relative" variant="unstyled">
              <HStack justifyContent={"start"} w="full" flex="1">
                <Box mr="auto">
                  <TabList>
                    <Tab fontWeight={900} fontSize="1rem">
                      Tareas para hoy
                    </Tab>
                    <Tab fontWeight={900} fontSize="1rem">
                      Tareas de la semana
                    </Tab>
                    <Tab fontWeight={900} fontSize="1rem">
                      Todas las tareas
                    </Tab>
                  </TabList>
                  <TabIndicator
                    mt="-1.5px"
                    height="3px"
                    bg="brand.blue1"
                    borderRadius="1px"
                  />
                </Box>
                <Menu closeOnSelect={false}>
                  <MenuButton colorScheme="none">
                    <IconButton
                      isRound={true}
                      variant="brand"
                      bg="brand.blue1"
                      aria-label="Done"
                      fontSize="20px"
                      ml="auto"
                      icon={<FilterIcon />}
                    />
                  </MenuButton>
                  <MenuList minWidth="240px">
                    <MenuOptionGroup
                      defaultValue={filter}
                      type="radio"
                      onChange={(value) => setFilter(value)}
                    >
                      <MenuItemOption value="">Mostrar todos</MenuItemOption>
                      <MenuItemOption value="operativas">
                        Tareas operativas
                      </MenuItemOption>
                      <MenuItemOption value="programadas">
                        Tareas programadas
                      </MenuItemOption>
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
              </HStack>
              <TabPanels>
                <TabPanel>
                  <HeaderTab />
                  <TableContainer>
                    <Table
                      style={{
                        borderCollapse: "separate",
                        borderSpacing: "0 1em",
                      }}
                    >
                      <TableHeads user={user} />
                      <Tbody>
                        {todayData.map((activity, i) => (
                          <TareaTableRow
                            key={`tr_today_${i}`}
                            activity={activity}
                            dataStatus={dataStatus}
                            user={user}
                            statusHandlerSelection={statusHandlerSelection}
                            userOnCheck={userOnCheck}
                            setDataDetail={setDataDetail}
                            onDetailOpen={onDetailOpen}
                          />
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </TabPanel>
                <TabPanel>
                  <HeaderTab />
                  <TableContainer>
                    <Table
                      style={{
                        borderCollapse: "separate",
                        borderSpacing: "0 1em",
                      }}
                    >
                      <TableHeads user={user} />
                      <Tbody>
                        {weeklyData.map((activity, i) => (
                          <TareaTableRow
                            key={`tr_today_${i}`}
                            activity={activity}
                            dataStatus={dataStatus}
                            user={user}
                            statusHandlerSelection={statusHandlerSelection}
                            userOnCheck={userOnCheck}
                            setDataDetail={setDataDetail}
                            onDetailOpen={onDetailOpen}
                          />
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </TabPanel>
                <TabPanel>
                  <HeaderTab />
                  <TableContainer>
                    <Table
                      style={{
                        borderCollapse: "separate",
                        borderSpacing: "0 1em",
                      }}
                    >
                      <TableHeads user={user} />
                      <Tbody>
                        {allTareas.map((activity, i) => (
                          <TareaTableRow
                            key={`tr_overall_${i}`}
                            activity={activity}
                            dataStatus={dataStatus}
                            user={user}
                            statusHandlerSelection={statusHandlerSelection}
                            userOnCheck={userOnCheck}
                            setDataDetail={setDataDetail}
                            onDetailOpen={onDetailOpen}
                          />
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </TabPanel>
              </TabPanels>
            </Tabs>
          )}
        </VStack>
      </Box>
    </>
  );
};

export default Tareas;
