import {
  Box,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Flex,
  VStack,
  Button,
  useToast,
  Spinner,
  WrapItem,
  Avatar,
  AlertDialog,
  useDisclosure,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Tooltip,
} from "@chakra-ui/react";

import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";

import { ROLES, TOAST_TIME } from "../constans/utils";
import {
  useDeleteActivityMutation,
  useGetEstatusQuery,
  useLazyGetActivitiesInactiveQuery,
  useUpdateWorkMutation,
} from "../store/heraApi";
import TareaTableRow from "../components/TareaTableRow";
import { useGetToken } from "../hooks/useAuth";

import Markdown from "react-markdown";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { orderBy } from "lodash";

dayjs.extend(isSameOrBefore);

const HeaderTab = () => {
  return (
    <HStack w="full" mb="12" mt="6">
      <HStack mr="6">
        <Box h="27px" w="8px" rounded="xl" mr="2" bg="brand.blue2" />
        <Text fontWeight={800} color="brand.blue2" fontSize="sm">
          Tareas operativas
        </Text>
      </HStack>
      <HStack>
        <Box h="27px" w="8px" rounded="xl" mr="2" bg="brand.green1" />
        <Text fontWeight={800} color="brand.green1" fontSize="sm">
          Tareas programadas
        </Text>
      </HStack>
    </HStack>
  );
};

const TableHeads = ({ user }) => {
  return (
    <Thead>
      <Tr>
        <Th color="brand.blue1">Tarea</Th>
        <Th color="brand.blue1">Fecha de entrega</Th>
        <Th color="brand.blue1">Status</Th>
        <Th color="brand.blue1">Check usuario</Th>
        {Array.isArray(user?.rol) && user?.rol?.join("") === ROLES.USER && (
          <Th color="brand.blue1">Check supervisor</Th>
        )}
        {Array.isArray(user?.rol) && user?.rol?.join("") !== ROLES.USER && (
          <Th color="brand.blue1">Asignados / Check supervisor</Th>
        )}
      </Tr>
    </Thead>
  );
};

const Delayed = () => {
  const [dataDetail, setDataDetail] = useState({});
  const cancelRef = useRef();
  const cancelDetailRef = useRef();

  const { user } = useGetToken();
  const [refetch, { data, isError, isLoading }] =
    useLazyGetActivitiesInactiveQuery();

  const [
    setActivityDeleted,
    { isError: isActError, isLoading: isActLoading, isSuccess: isActSuccess },
  ] = useDeleteActivityMutation();
  const [
    updateWork,
    {
      isLoading: isUpdateLoading,
      isError: isUpdateError,
      isSuccess: isUpdateSuccess,
    },
  ] = useUpdateWorkMutation();

  const { data: dataStatus } = useGetEstatusQuery();
  const toast = useToast();
  const {
    isOpen: isDetailOpen,
    onOpen: onDetailOpen,
    onClose: onDetailClose,
  } = useDisclosure();

  useEffect(() => {
    refetch().unwrap();
  }, [refetch, isActSuccess, isUpdateSuccess]);

  useEffect(() => {
    if (isActError || isUpdateError)
      toast({
        title: "Ooops!",
        description: "Ocurrió un error al realizar la acción",
        status: "error",
        duration: TOAST_TIME,
        isClosable: true,
      });
  }, [isActError, isUpdateError, toast]);

  useEffect(() => {
    if (isActSuccess || isUpdateSuccess)
      toast({
        title: "¡Éxito!",
        description: "Se realizó la acción con éxito.",
        status: "success",
        duration: TOAST_TIME,
        isClosable: true,
      });
  }, [isActSuccess, isUpdateSuccess, toast]);

  useEffect(() => {
    if (isError)
      toast({
        title: "Ooops!",
        description:
          "No se pudo obtener las tareas, verifica que tengas una sesión.",
        status: "error",
        duration: TOAST_TIME,
        isClosable: true,
      });
  }, [isError, toast]);

  const overallData = data ?? [];
  const allTareas = orderBy(
    overallData,
    [
      (activity) => {
        const nextDate = dayjs.utc(activity.next_date);
        return nextDate;
      },
      "daily",
      "weekly",
      "monthly",
      "yearly",
    ],
    ["asc", "desc", "desc", "desc", "desc"]
  );

  return (
    <>
      <AlertDialog
        isOpen={isDetailOpen}
        leastDestructiveRef={cancelDetailRef}
        estoy
        hac
        onClose={() => {
          onDetailClose();
          setDataDetail({});
        }}
        size={"4xl"}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {dataDetail?.name}
            </AlertDialogHeader>

            <AlertDialogBody>
              <HStack>
                {dataDetail?.start_date && (
                  <Box pr="2" borderRight={"1px solid"}>
                    Fecha inicio:{" "}
                    {dayjs(dataDetail?.start_date).format("DD/MM/YYYY")}
                  </Box>
                )}
                {dataDetail?.finish_date && (
                  <Box
                    textColor={
                      dayjs(dataDetail?.finish_date).isSameOrBefore(
                        Date.now(),
                        "day"
                      )
                        ? "red.300"
                        : "black"
                    }
                  >
                    Fecha fin:{" "}
                    {dayjs(dataDetail?.finish_date).format("DD/MM/YYYY")}
                  </Box>
                )}
              </HStack>
              {dataDetail?.description && (
                <Box
                  maxW="4xl"
                  w="full"
                  mx="auto"
                  borderWidth="1px"
                  bordercolor="gray.200"
                  rounded="lg"
                  mb="12"
                  mt="4"
                  px="8"
                  py="6"
                >
                  <Markdown>{dataDetail?.description}</Markdown>
                </Box>
              )}
              {dataDetail?.daily && (
                <VStack mb="4">
                  <Text fontWeight={800} fontSize="1.3em">
                    Se repite todos los días
                  </Text>
                </VStack>
              )}
              {dataDetail?.weekly && (
                <VStack mb="4">
                  <Text fontWeight={800} fontSize="1.3em">
                    Todas las semanas, los días:{" "}
                  </Text>
                  <HStack>
                    {dataDetail?.periodicity_weekly.map(({ label }, i) => (
                      <Box
                        key={`weekly-${i}`}
                        py="2"
                        px="4"
                        rounded="xl"
                        border="1px"
                        borderColor={"gray.300"}
                      >
                        {label}
                      </Box>
                    ))}
                  </HStack>
                </VStack>
              )}
              {dataDetail?.monthly && (
                <VStack mb="4">
                  <Text fontWeight={800} fontSize="1.3em">
                    Todos los meses en los días:{" "}
                  </Text>
                  <HStack>
                    {dataDetail?.monthly_periodicity_days.map(
                      ({ value }, i) => (
                        <Box
                          key={`monthly-${i}`}
                          py="2"
                          px="4"
                          rounded="xl"
                          border="1px"
                          borderColor={"gray.300"}
                        >
                          {value}
                        </Box>
                      )
                    )}
                  </HStack>
                </VStack>
              )}
              {dataDetail?.yearly && (
                <VStack mb="4">
                  <Text fontWeight={800} fontSize="1.3em">
                    Se repite cada año por cada{" "}
                    {dataDetail?.yearly_each_periodicity_months} mes(es).
                  </Text>
                </VStack>
              )}
              {(dataDetail?.users_assigned ?? [])?.length > 0 && (
                <VStack>
                  <Text>Asignados: </Text>
                  <HStack>
                    {(dataDetail?.users_assigned ?? [])
                      .map(({ label, value }) => {
                        const [name, username] = label.split(" - ");
                        return {
                          name,
                          username,
                          id: value,
                        };
                      })
                      .map(({ id, name }) => (
                        <Box key={`user-${id}-${name}`}>
                          <Tooltip key={`operative-${id}-${name}`} label={name}>
                            <WrapItem>
                              <Avatar
                                size="sm"
                                name={name}
                                borderColor="brand.blue1"
                                borderWidth={"1px"}
                              />
                            </WrapItem>
                          </Tooltip>
                        </Box>
                      ))}
                  </HStack>
                </VStack>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              {!user?.rol?.includes("user") && (
                <Button
                  bgColor="red.400"
                  color="white"
                  onClick={() => {
                    onDetailClose();
                    setActivityDeleted(dataDetail.uuid).unwrap();
                  }}
                  mr="4"
                  isLoading={isActLoading}
                  loadingText="Cargando"
                >
                  Eliminar
                </Button>
              )}
              {!user?.rol?.includes("user") && (
                <Button
                  onClick={() => {
                    onDetailClose();
                    updateWork({
                      uuid: dataDetail?.uuid,
                      payload: { active: true },
                    });
                  }}
                  mr="4"
                  isLoading={isUpdateLoading}
                  colorScheme="green"
                  loadingText="Cargando"
                >
                  Activar tarea
                </Button>
              )}
              <Button
                ref={cancelRef}
                onClick={() => {
                  onDetailClose();
                  setDataDetail({});
                }}
              >
                Cerrar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Box
        minWidth={{ lg: "max-content", md: "inherit", base: "inherit" }}
        direction={{ lg: "row", base: "column" }}
        w="full"
        p={{ lg: 8, base: 4 }}
      >
        <VStack p="4" rounded="xl" bg="none">
          <Flex w="full" mb="6" direction={{ base: "column", md: "row" }}>
            <HStack
              p="6"
              rounded="xl"
              bg="white"
              minW={{ base: "full", md: "xs" }}
              mr="auto"
            >
              <WrapItem>
                <Avatar
                  size="lg"
                  name={user?.name}
                  borderColor="brand.blue1"
                  borderWidth={"1px"}
                  mr="2"
                />
              </WrapItem>
              <VStack alignItems={"start"} mr="auto">
                <Text fontSize="18" fontWeight={900} mb="0">
                  {user?.name}
                </Text>
                <Text
                  fontSize="xs"
                  fontWeight={100}
                  textTransform={"uppercase"}
                >
                  {user?.rol?.join("")}
                </Text>
              </VStack>
            </HStack>
          </Flex>

          {isLoading ? (
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          ) : (
            <Box w="full">
              <HeaderTab />
              <TableContainer>
                <Table
                  style={{
                    borderCollapse: "separate",
                    borderSpacing: "0 1em",
                  }}
                >
                  <TableHeads user={user} />
                  <Tbody>
                    {allTareas.map((activity, i) => (
                      <TareaTableRow
                        key={`tr_today_${i}`}
                        activity={activity}
                        dataStatus={dataStatus}
                        user={user}
                        setDataDetail={setDataDetail}
                        onDetailOpen={onDetailOpen}
                      />
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </VStack>
      </Box>
    </>
  );
};

export default Delayed;
