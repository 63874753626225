import { Spinner, Box } from "@chakra-ui/react";
import { Calendar, dayjsLocalizer } from "react-big-calendar";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";

import { useGetActivitiesCalendarQuery } from "../store/heraApi";
import { TYPES } from "../constans/utils";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "../calendar.css";
import "dayjs/locale/es-mx";

dayjs.locale("es-mx");

dayjs.extend(timezone);

const localizer = dayjsLocalizer(dayjs);

const CustomComponent = (props) => {
  return (
    <Box
      bg={
        props?.event?.type === TYPES.OPERATIVE ? "brand.blue1" : "brand.green1"
      }
      color={"white"}
      textAlign={"center"}
      borderColor={
        props?.event?.type === TYPES.OPERATIVE ? "brand.blue1" : "brand.green1"
      }
      px="4"
      py="1"
      fontSize="xs"
    >
      {props.title}
    </Box>
  );
};

const Agenda = () => {
  const { data, isLoading, isSuccess } = useGetActivitiesCalendarQuery();

  if (isLoading && isSuccess && Array.isArray(data)) {
    return (
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    );
  }

  return (
    <Box
      p={{ md: 8, base: 6 }}
      w={{ base: "full", lg: "8xl" }}
      bg="white"
      rounded="lg"
      shadow="sm"
    >
      <Calendar
        messages={{
          week: "Semana",
          work_week: "Semana de trabajo",
          day: "Día",
          month: "Mes",
          previous: "Atrás",
          next: "Siguiente",
          today: "Hoy",
          agenda: "Diariamente",

          showMore: (total) => `+${total} más`,
        }}
        culture="es"
        localizer={localizer}
        events={(data ?? []).map((act) => {
          return {
            ...act,
            start: new Date(act.start),
            end: new Date(act.start === act.end ? act.start : act.end),
            allDay: true,
          };
        })}
        dayLayoutAlgorithm="no-overlap"
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500, width: "100%" }}
        components={{ event: CustomComponent }}
      />
    </Box>
  );
};

export default Agenda;
