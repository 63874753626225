import { useReducer, useEffect, useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Select as ChakraSelect,
  Button,
  useToast,
  Divider,
} from "@chakra-ui/react";
import { cloneDeep, set } from "lodash";
import Select from "react-select";

import {
  useLazyGetAreasQuery,
  useSetNewUserMutation,
  useUpdateUserMutation,
} from "../../store/heraApi";
import { useGetToken } from "../../hooks/useAuth";

const STATE = {
  username: "",
  telefono: "",
  name: "",
  descripcion: "",
  password: "",
  rol: [],
  areas: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INPUT":
      const cloned = cloneDeep(state);
      set(cloned, action.key, action.value);
      return cloned;
    case "RESET":
      const clonedState = cloneDeep(STATE);
      return clonedState;
    default:
      return state;
  }
};

const UserForm = ({ userSelected }) => {
  const { isAdmin } = useGetToken();
  const [isEditing, setIsEditing] = useState(false);
  const [modelObject, dispatch] = useReducer(reducer, STATE);
  const [areasAdded, setAreasAdded] = useState([]);

  const [setNewUser, { isLoading, isError, isSuccess }] =
    useSetNewUserMutation();
  const [
    updateUser,
    {
      isLoading: isUpdatingLoading,
      isError: isUpdatingError,
      isSuccess: isUpdatingSuccess,
    },
  ] = useUpdateUserMutation();
  const [areasFetcher, { data: allAreas }] = useLazyGetAreasQuery();
  const toast = useToast();

  useEffect(() => {
    if (Array.isArray(modelObject?.Usuario_Areas) && Array.isArray(allAreas)) {
      const areasMapped = modelObject?.Usuario_Areas?.reduce(
        (arr, { area_id }) => {
          const area = allAreas?.find(
            ({ id }) => Number(id) === Number(area_id)
          );
          if (area) {
            arr.push({
              label: area.name,
              value: area.id,
            });
          }
          return arr;
        },
        []
      );
      setAreasAdded(areasMapped);
    }
  }, [modelObject?.Usuario_Areas, allAreas]);

  useEffect(() => {
    if (isError || isUpdatingError)
      toast({
        title: "Ooops!",
        description:
          "Hubo un error al querer guardar los datos del usuario usuario, intenta de nuevo",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
  }, [isError, isUpdatingError, toast]);

  useEffect(() => {
    if (isSuccess || isUpdatingSuccess) {
      toast({
        title: "¡Exito!",
        description: "Se realizó la acción con éxito",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      dispatch({ type: "RESET" });
    }
  }, [isSuccess, isUpdatingSuccess, toast]);

  useEffect(() => {
    if (userSelected?.id) {
      setIsEditing(true);
      Object.keys(userSelected).forEach((key) => {
        handlerReducer(userSelected[key], key);
      });
    }
  }, [userSelected, userSelected?.id]);

  useEffect(() => {
    areasFetcher();
  }, [areasFetcher]);

  const handlerReducer = (value, key) => {
    dispatch({ type: "INPUT", value, key });
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (!isAdmin) {
          toast({
            title: "Ooops!",
            description:
              "No cuentas con los permisos suficientes, verifícalo con tu superior.",
            status: "warning",
            duration: 9000,
            isClosable: true,
          });
          return;
        }
        if (modelObject.rol.length === 0) {
          toast({
            title: "Ooops!",
            description:
              "Antes de enviar la información llena todos los campos requeridos.",
            status: "warning",
            duration: 9000,
            isClosable: true,
          });
          return;
        }
        if (!isEditing) setNewUser(modelObject).unwrap();
        else if (isEditing)
          updateUser({ id: modelObject.id, payload: modelObject }).unwrap();
      }}
    >
      <FormControl mt="4" mb="2" isRequired>
        <FormLabel>Nombre de usuario</FormLabel>
        <Input
          type="text"
          value={modelObject.username}
          onInput={(e) => handlerReducer(e.target.value, "username")}
        />
      </FormControl>
      <FormControl mt="4" mb="2">
        <FormLabel>Nombre Completo</FormLabel>
        <Input
          type="text"
          value={modelObject.name}
          onInput={(e) => handlerReducer(e.target.value, "name")}
        />
      </FormControl>
      <FormControl mt="4" mb="2">
        <FormLabel>Teléfono</FormLabel>
        <Input
          type="text"
          value={modelObject.telefono}
          maxLength={"10"}
          onInput={(e) => handlerReducer(e.target.value, "telefono")}
        />
      </FormControl>
      <FormControl mt="4" mb="2">
        <FormLabel>Descripción del puesto</FormLabel>
        <Input
          type="text"
          value={modelObject.descripcion}
          onInput={(e) => handlerReducer(e.target.value, "descripcion")}
        />
      </FormControl>

      {!isEditing && (
        <FormControl mt="4" mb="2" isRequired>
          <FormLabel>Contraseña</FormLabel>
          <Input
            type="password"
            value={modelObject.password}
            onInput={(e) => handlerReducer(e.target.value, "password")}
          />
        </FormControl>
      )}
      <Divider my="4" />
      <FormControl mt="4" mb="2" isRequired>
        <FormLabel>Asignar rol:</FormLabel>
        <ChakraSelect
          placeholder="Selecciona un rol"
          value={modelObject.rol}
          onChange={(e) => handlerReducer([e.target.value], "rol")}
        >
          <option value="user">Usuario</option>
          <option value="supervisor">Supervisor</option>
          <option value="admin">Administrador</option>
        </ChakraSelect>
      </FormControl>
      {Array.isArray(allAreas) && (
        <FormControl mt="4" mb="2" isRequired>
          <FormLabel>Asignar áreas:</FormLabel>
          <Select
            isMulti
            options={allAreas.map(({ id, name }) => {
              return {
                label: name,
                value: id,
              };
            })}
            value={
              isEditing && modelObject.areas.length === 0
                ? areasAdded
                : modelObject.areas
            }
            onChange={(value) => handlerReducer(value, "areas")}
          />
        </FormControl>
      )}

      <Button
        type="submit"
        isLoading={isLoading || isUpdatingLoading}
        loadingText="Enviando información"
        mt="6"
        bg="brand.blue1"
        color="white"
      >
        Enviar información
      </Button>
    </form>
  );
};

export default UserForm;
