import { BACKEND_SERVER } from "./utils";

const base = BACKEND_SERVER;

const headers = {
  "Content-Type": "application/json",
};

export default class Api {
  http;
  constructor() {
    this.http = new Http(base);
  }

  get base() {
    return this.http.base;
  }
}

class Http {
  _base;
  constructor(base) {
    this._base = base;
  }
  get base() {
    return this._base;
  }

  async get(url, token = "", options = {}) {
    if (token) {
      headers.authorization = `Bearer ${token}`;
    }
    const res = await fetch(`${this.base}${url}`, {
      ...options,
      method: "GET",
      headers: headers,
    });
    const data = await res.json();
    return data;
  }

  async post(url, body, token = "", options = {}) {
    if (token) {
      headers.authorization = `Bearer ${token}`;
    }
    try {
      const res = await fetch(`${this.base}${url}`, {
        ...options,
        method: "POST",
        headers: headers,
        body: JSON.stringify(body),
      });
      const data = await res.json();
      return data;
    } catch (error) {
      return error;
    }
  }

  async getStream(url, token = "", options = {}) {
    if (token) {
      headers.authorization = `Bearer ${token}`;
    }
    const res = await fetch(`${this.base}${url}`, {
      ...options,
      method: "GET",
      headers: headers,
    });
    const data = await res.blob();
    return data;
  }
}
