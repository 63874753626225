import {
  Avatar,
  Menu,
  WrapItem,
  Button,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const UserMenu = ({ user }) => {
  const navigate = useNavigate();
  const toast = useToast();

  return (
    <Menu>
      <MenuButton as={Button}>
        <WrapItem>
          <Avatar
            size="sm"
            name={user?.name}
            borderColor="brand.blue1"
            borderWidth={"1px"}
          />
        </WrapItem>
      </MenuButton>
      <MenuList>
        <MenuItem onClick={() => navigate("/perfil")}>Mi perfil</MenuItem>
        <MenuItem
          onClick={() => {
            localStorage.removeItem("session");
            toast({
              title: "¡Exito!",
              description: "Se cerró sesión con éxito",
              status: "success",
              duration: 9000,
              isClosable: true,
            });
            navigate("/");
          }}
        >
          Cerrar sesión
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default UserMenu;
