import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const useGetToken = () => {
  const [token, setToken] = useState();
  const [user, setUser] = useState();
  const [isUser, setIsUser] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSupervisor, setIsSupervisor] = useState(false);

  useEffect(() => {
    const session = localStorage.getItem("session");
    if (session && typeof session === "string") {
      const sessionObject = JSON.parse(session);
      if (sessionObject?.access_token) setToken(sessionObject?.access_token);
      if (sessionObject?.user) {
        setUser(sessionObject?.user);
        if (sessionObject?.user?.rol?.includes("user")) setIsUser(true);
        if (sessionObject?.user?.rol?.includes("admin")) setIsAdmin(true);
        if (sessionObject?.user?.rol?.includes("supervisor"))
          setIsSupervisor(true);
      }
    }
  }, []);

  return { token, user, isUser, isAdmin, isSupervisor };
};

export const useAuth = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const session = localStorage.getItem("session");
    if (!session && typeof session !== "string") {
      navigate("/");
    }
  }, [navigate]);
};
