import { useEffect, useState } from "react";
import { differenceWith, find, get, isEqual } from "lodash";
import { useGetToken } from "./useAuth";

export default function useProcessDataHandler(
  data,
  dataAreasByUser,
  user,
  typeProcess,
  filterByUsers
) {
  const [overallData, setOverallData] = useState([]);
  const { isUser, isSupervisor } = useGetToken();

  useEffect(() => {
    if (Array.isArray(data)) {
      let filtered = data.filter(
        (act) => act?.additional?.type_process === typeProcess
      );

      if (
        (isUser || isSupervisor) &&
        Array.isArray(dataAreasByUser) &&
        dataAreasByUser.length > 0
      ) {
        const areasByUser = dataAreasByUser?.map(({ id }) => id) ?? [];
        filtered = filtered.filter((act) => {
          const areasAssigned = get(act, "Activities_Areas_Assigned", []);
          const usersAssigned = get(act, "users_assigned", []);
          const areasMapped = areasAssigned.map(({ areas_id }) => areas_id);
          if (filterByUsers === true && usersAssigned.length > 0) {
            // console.log("FIRST IF");
            return find(usersAssigned, { value: user.sub || user.id });
          } else if (Array.isArray(areasAssigned) && areasAssigned.length > 0) {
            // console.log("SECOND IF");
            // console.log("areasByUser", areasByUser);
            // console.log("areasMapped", areasMapped);
            // console.log("act", act);

            const comparison = differenceWith(
              areasMapped,
              areasByUser,
              isEqual
            );

            return (
              comparison.length === 0 || comparison.length < areasMapped.length
            );
          }
          return false;
        });
      }
      setOverallData(filtered);
    }
  }, [
    data,
    user,
    dataAreasByUser,
    typeProcess,
    filterByUsers,
    isUser,
    isSupervisor,
  ]);

  return overallData;
}
